
import { faBoxArchive } from '@fortawesome/pro-light-svg-icons';
import { faArrowRight, faBroomWide, faCircleCheck, faCirclePlus, faCircleQuestion, faCircleXmark, faClock, faFloppyDiskCircleArrowRight, faHourglassClock, faInfoCircle, faMessageLines, faMug, faRightFromBracket, faTriangleExclamation as farTriangleExclamation, faSackDollar } from '@fortawesome/pro-regular-svg-icons';
import { faClone, faEmptySet, faLinkSlash, faShield, faMug as faSolidMug, faTriangleExclamation as fasTriangleExclamation, faUsers, faXmark } from '@fortawesome/pro-solid-svg-icons';
import { faHorizontalRule } from '@fortawesome/pro-thin-svg-icons';
import FA from './fontawesome';
import { IconPacks } from './IconPacks';

/**
 * Pack used for the sunkhronos library
 */
export const sunkhronosPack = FA.createIconPack(
    IconPacks.Sunkhronos,
    faHorizontalRule,
    faSackDollar,
    faArrowRight,
    faCirclePlus,
    faMessageLines,
    faSolidMug,
    faMug,
    faClock,
    faCircleCheck,
    faHourglassClock,
    fasTriangleExclamation,
    faRightFromBracket,
    faCircleQuestion,
    faBoxArchive,
    faXmark,
    faInfoCircle,
    faUsers,
    faCircleXmark,
    farTriangleExclamation,
    faClone,
    faEmptySet,
    faFloppyDiskCircleArrowRight,
    faBroomWide,
    faShield,
    faLinkSlash
);