import { SegmentedValue } from 'antd/lib/segmented';
import moment, { Moment } from 'moment';
import { RangeValue } from "rc-picker/lib/interface";
import * as api from './api';
import { GOOGLE_STORAGE_URL, MOMENT_FORMAT, MOMENT_FORMAT_DATE_TO_NETWORK, MOMENT_FORMAT_TO_NETWORK } from './constants';
import { IAggregatedEvent, ICustomerNetwork, ICustomerStat, IMandate, IMission, IMissionEdit, IMissionRule, IMissionRuleEdit, IMissionStatus, ISite } from './types/customerTypes';
import { Course, CustomerLoyalty, EditSMSTemplate, Emergency, EmergencyContact, EmergencyHeader, EmergencyNumber, EventClockedAdminBody, Group, MobileAppSettings, MobileAppTheme, POI, PreValidateFile, Sector, SMSSend, StaffType, User, UserJobTMP, ValidateFile } from './types/generalTypes';
import { CalendarDataBodyRequest, CategoryRightsBody, CreateMessageBody, CreateNewsBody, CreateUserBodyRequest, ListingCategoryBody, ListingDirectoryBody, ListingSubCategoryBody, LoadClientBodyRequest, MailBodyRequest, NetworkDaysOffByType, NetworkEvent, NetworkEventClockedsConfirmRejectSetAdmin, NetworkEventClockedSetManually, NetworkMajoration, NetworkOccupancyRateExclusion, NetworkResponse, NetworkSetEventParameters, NetworkSettings, NetworkTypeOfContract, NetworkTypeOfVehicle, NetworkUserHoursSummaries, NetworkUserHoursSummariesWithContractDetails, NetworkUserImport, NetworkUserImportResponse, NetworkUserYearlyParams, NetworkVacations, NewtworkTypeOfDayOff, OccupancyRateBodyRequest, PromotionBodyRequest, SMSBodyRequest, UserAssignGroupBody, UserAvailabilityEditNetwork, UserExtraVacationsBodyRequest, UserVacationsBodyRequest } from './types/networkTypes';
import { CopyEventsBodyRequest, EventAvailability, MonthlyHoursOfTheYear, PlanningEvent, PlanningExclusion, PlanningOvertime, PlanningPeriod, PlanningTemplate, Project, ReportSettings, SimpleSettings, TypeOfDay, UserAvailabilityBodyRequest, UserAvailabilityDefault } from './types/planningTypes';
import { BalanceYearRequestBody } from './types/requestBodyTypes';
import { convertPlanningEventToNetworkEvent, convertPlanningExclusionToNetworkExclusion, convertPlanningOvertimeToNetworkOvertime, convertPlanningPeriodToNetworkPeriod, convertPlanningTemplateToNetworkTemplate } from './utils';

// eslint-disable-next-line @typescript-eslint/no-var-requires
const Compress = require('compress.js').default;

/**
 * Static class with Network API call methods
 */
export default class Network {
    static getAppVersion = (appName: string): Promise<any> => api.apiRequestGet(`utils/app/version?app_name=${appName}`);

    // User

    /**
     * Login the user
     * @param username the user's username
     * @param password the user's password
     * @returns a promise containing the response of the request
     */
    static login = (username: string, password: string, rememberMe?: boolean): Promise<any> => api.login(username, password, rememberMe);
    static logout = (token: string): Promise<any> => api.logout(token);

    /**
      * Get the current user details
      * @returns a promise containing the response of the request
      */
    static getCurrentUser = (): Promise<any> => api.apiRequestGet('user/current');

    // Reset password

    /**
     * Reset a user password
     * @param email the concerned user's email
     * @param language the language of the email that will be sent to the user
     * @returns a promise containing the response of the request
     */
    static userResetPassword = (email: string, language?: string): Promise<any> => api.apiRequest('user/forgot-password', { email, language });

    /**
     * Start the process for a forgotten password
     * @param email the user's email
     * @returns a promise containing the response of the request
     */
    static forgotPassword = (email: string): Promise<any> => api.apiRequest('user/forgot-password/', { email });

    /**
     * Check if the reset password token exists
     * @param token the token to check
     * @returns a promise containing the response of the request
     */
    static checkResetPasswordToken = (token: string): Promise<any> => api.apiRequest('user/reset-password-token/', { token });

    /**
     * Reset a user's password
     * @param email the user's email
     * @param password the new password
     * @param token the reset password token
     * @returns a promise containing the response of the request
     */
    static resetPassword = (email: string, password: string, token: string): Promise<any> => api.apiRequest('user/password-reset/', { email, password, token });
    static usernameIsValid = (username: string, id?: number): Promise<any> => api.apiRequestGet(`user/username-is-valid?username=${username}${id ? `&id=${id?.toString()}` : ''}`);

    static getUsersLoggedIn = (): Promise<any> => api.apiRequestGet(`user/users/logged-in`);
    static deleteAuthToken = (tokenId: number): Promise<any> => api.apiRequestDelete(`user/users/logged-in?tokenId=${tokenId}`);
    // Dashboard

    /**
     * Get the statistics
     * @returns a promise containing the response of the request
     */
    static getUsersAvailabilities = (usersEventAvailability: EventAvailability): Promise<any> => api.apiRequestGet(`planning/users/get-availabilities?startDate=${usersEventAvailability.startDate.format(MOMENT_FORMAT)}&endDate=${usersEventAvailability.endDate.format(MOMENT_FORMAT)}`);
    static getDashboardStatistics = (): Promise<any> => api.apiRequestGet('planning/dashboard/statistics/');
    static getDashboardFastStatistics = (): Promise<any> => api.apiRequestGet('planning/dashboard/stats/');
    static getPlanningStatistics = (companyId: number, day?: string): Promise<any> => api.apiRequestGet(`planning/planning-statistics?companyId=${companyId}${day ? `&day=${day}` : ""}`);

    /**
     * Get dashboard occupancy rates informations
     * @returns a promise containing the response of the request
     */
    static getDashboardOccupancyRates = (startDate: string, endDate: string): Promise<any> => api.apiRequestGet(`planning/dashboard/occupancy-rates?startDate=${startDate}&endDate=${endDate}`);
    static getDashboardOccupancyRatesV2 = (startDate: string, endDate: string): Promise<any> => api.apiRequestGet(`planning/dashboard/occupancy-rates/v2?startDate=${startDate}&endDate=${endDate}`);

    /**
     * Get dashboard expiry contracts informations
     * @returns a promise containing the response of the request
     */
    static getDashboardExpiryContracts = (): Promise<any> => api.apiRequestGet('planning/dashboard/expiry-contracts');
    static getDashboardExpiryFiles = (): Promise<any> => api.apiRequestGet('planning/dashboard/expiry-files');

    /**
     * Get dashboard expiry users' extra vacations
     * @returns a promise containing the response of the request
     */
    static getDashboardUsersExtraVacations = (): Promise<any> => api.apiRequestGet('planning/dashboard/expiry-extra-vacations/');

    /**
     * Get company details
     * @returns a promise containing the response of the request
     */
    static getCompanyDetails = (): Promise<any> => api.apiRequestGet('user/company');

    static getOvertimesNotConfirmed = (): Promise<any> => api.apiRequestGet('planning/overtimes-not-confirmed');

    static refuseOvertimeNotConfirmed = (overtimeId: number): Promise<any> => api.apiRequest('planning/overtimes-not-confirmed', { "query": "refuse", overtimeId: overtimeId });
    static validateOvertimeNotConfirmed = (overtimeId: number): Promise<any> => api.apiRequest('planning/overtimes-not-confirmed', { "query": "validate", overtimeId: overtimeId });

    // Newsfeed

    /**
     * Get news list per page
     * @param currentPage the page to load
     * @param userFeed if only the userfeed must be loaded - optional
     * @returns a promise containing the response of the request
                    */
    static getNewsList = (currentPage: number, selectedFeed: 'userfeed' | 'newsfeed' | 'customers'): Promise<any> => {
        return api.apiRequestGet(`news/posts?page=${currentPage}&creator_type=${selectedFeed}`);
    };
    static getNewsComments = (newsId: number): Promise<any> => {
        return api.apiRequestGet(`news/post/comments?news_id=${newsId}`);
    };
    static createNewsComment = (newsId: number, text: string): Promise<any> => {
        return api.apiRequest(`news/post/comment`, { news_id: newsId, text });
    };
    static likeNews = (newsId: number): Promise<any> => {
        return api.apiRequest(`news/post/like`, { news_id: newsId, like_active: true });
    };
    static unlikeNews = (newsId: number): Promise<any> => {
        return api.apiRequest(`news/post/like`, { news_id: newsId, like_active: false });
    };

    /**
     * Create a news
     * @param body the request's body
     * @returns a promise containing the response of the request
     */
    static createNews = (body: CreateNewsBody): Promise<any> => {

        const uploadDynamicFile = (file: File, fileIndex: number, fileName: string) => {
            return new Promise<string>((resolve, reject) => {
                Network.putSignedUrlV2('ski_image', fileName).then(
                    response => {
                        const temp_url = response.sub_url;
                        const signed_url = response.sign_url;

                        const reader = new FileReader();
                     
                        reader.onload = (e) => {
                            api.apiRequestFileUpload(signed_url, e.target?.result).then(
                                () => {
                                    resolve(temp_url);
                                },
                                (error) => reject(error),
                            );
                        };
                        reader.readAsArrayBuffer(file);
                    },
                    error => reject(error)
                );
            });
        };
        // const uploadDynamicImage = (fileIndex: number, fileName?: string) => {
        //     return new Promise<string>((resolve, reject) => {
        //         Network.putSignedUrlV2('ski_image', fileName).then(
        //             response => {
        //                 const temp_url = response.sub_url;
        //                 const signed_url = response.sign_url

        //                 const reader = new FileReader();
        //                 const foundFile = body.images?.at(fileIndex);
        //                 if (foundFile === undefined) {
        //                     reject('Image not found');
        //                     return;
        //                 }

        //                 reader.onload = (e) => {
        //                     api.apiRequestFileUpload(signed_url, e.target?.result).then(
        //                         () => {
        //                             body.images![fileIndex] = temp_url;
        //                             resolve(temp_url);
        //                         },
        //                         (error) => reject(error),
        //                     );
        //                 };
        //                 reader.readAsArrayBuffer(body.images![fileIndex] as File);
        //             },
        //             error => reject(error)
        //         );
        //     });
        // }

        //if there is no image, directly create the news
        const images: File[] | undefined = (body.images !== undefined && body.images.length > 0) ? body.images.filter(i => typeof i !== 'string') as File[] : undefined;
        if ((body.files === undefined || body.files.length === 0) && (images === undefined || images.length === 0)) {
            console.log("SKIP UPLOAD", body, images);
            return api.apiRequest('news/posts', body);
        }
        console.log("DID NOT SKIP UPLOAD", body, images);


        //otherwise upload the image
        return new Promise(async (resolve, reject) => {
            const files: {
                file_name: string;
                res_url: string;
                res_type: 'IMG' | 'VDO' | 'FLE';
            }[] = [];
            if (body.images !== undefined) {
                for (let i = 0; i < body.images.length; i++) {
                    const file = body.images[i];
                    console.log("UPLOAD", body, images, file);

                    if (file !== undefined && typeof file !== "string") {
                        try {
                            await uploadDynamicFile(file as File, i, file.name).then(
                                (image_url) => files.push({
                                    file_name: file.name,
                                    res_url: image_url,
                                    res_type: 'IMG'
                                })
                            );
                        } catch (error) {
                            reject(error);
                        }
                    } else {
                        typeof file !== 'string' && files.push(file);
                    }
                }
            }
            if (body.files !== undefined) {
                for (let i = 0; i < body.files.length; i++) {
                    const file = body.files[i];
                    if (file !== undefined && typeof file !== "string" && file instanceof File) {
                        try {
                            await uploadDynamicFile(file as File, i, file.name).then(
                                (file_url) => files.push({
                                    file_name: file.name,
                                    res_url: file_url,
                                    res_type: 'FLE'
                                })
                            );
                        } catch (error) {
                            reject(error);
                        }
                    } else {
                        typeof file !== 'string' && files.push(file);
                    }
                }
            }
            body.files = files;
            resolve(api.apiRequest('news/posts', body));

        });
    };

    /**
     * Delete a news
     * @param body the request's body
     * @returns a promise containing the response of the request
     */
    static deleteNews = (body: any): Promise<any> => api.apiRequest('news/post/delete', body);

    // Messages

    /**
     * Get messages per pages
     * @param currentPage the page to load
     * @returns a promise containing the response of the request
     */
    static getMessages = (page: number): Promise<any> => api.apiRequestGet(`notification/message?page_no=${page} &source=admin`);

    static getFullSMS = (): Promise<any> => api.apiRequestGet(`notification/sms/full`);
    static getSMSSent = (): Promise<any> => api.apiRequestGet(`notification/sms/sent`);
    static sendSMS = (sms: SMSSend): Promise<any> => api.apiRequest('notification/sms/send', sms);
    static reloadSMSBlacklist = (force = false): Promise<any> => api.apiRequestGet(`notification/sms/blacklist?force=${force}`);
    static getContacts = (type: string): Promise<any> => api.apiRequestGet(`notification/sms/contact/list?type=${type}`);
    static getMoreCredit = (amount: number, thresholdLimit: number): Promise<any> => api.apiRequestPut('notification/sms/account/credit', { amount: amount, thresholdLimit: thresholdLimit }); // apiRequestPut
    static getSMSTemplate = (): Promise<any> => api.apiRequestGet('notification/sms/template');
    static saveSMSTemplate = (template: EditSMSTemplate): Promise<any> => api.apiRequest('notification/sms/template', template);
    static deleteSMSTemplate = (ids: number[]): Promise<any> => api.apiRequestDeleteWithBody('notification/sms/template', { ids: ids });
    static getTransfert = (): Promise<any> => api.apiRequestGet('notification/sms/account/credit');
    static getLanguages = (): Promise<any> => api.apiRequestGet('utils/languages');
    static getCountries = (): Promise<any> => api.apiRequestGet(`utils/countries`);
    static getRegions = (): Promise<any> => api.apiRequestGet('planning/countries');

    static getSMSTemplateFields = (): Promise<any> => api.apiRequestGet('notification/sms/template/fields');

    /**
     * Create a message
     * @param body the request's body
     * @returns a promise containing the response of the request
     */
    static createMessage = (body: CreateMessageBody): Promise<any> => api.apiRequest('notification/message', body);

    /**
     * Delete a message
     * @param messageId the message's id
     * @returns a promise containing the response of the request
     */
    static deleteMessage = (messageId: number): Promise<any> => api.apiRequestDelete(`notification/message?message_id=${messageId}`);

    // Team management

    /**
     * Get a user details
     * @param userId the user's id
     * @returns a promise containing the response of the request
     */
    static getUser = (userId: string | number): Promise<any> => api.apiRequestGet(`user/current?user_id=${userId}`);

    /**
     * Get all user from the current company
     * @returns a promise containing the response of the request
     */
    static getAllUsers = (): Promise<any> => api.apiRequestGet('user/list');
    static getAllArchivedUsers = (): Promise<any> => api.apiRequestGet('user/archived-list');
    static getGoogleMapsKey = (): Promise<any> => api.apiRequestGet('utils/google-maps-key');


    static syncUsersAndGroups = (): Promise<any> => api.apiRequestGet('user/sync-users-and-groups');

    /**
     * Get all groups from the current company
     * @param groupId the id of the group to retrieve
     * @param userId the id of the user for which fetch the groups
     * @returns a promise containing the response of the request
     */
    static getGroups = (groupId?: number, userId?: number): Promise<any> => api.apiRequestGet(`user/groups${groupId ? `?group_id=${groupId}` : ''}${userId ? `?user_id=${userId}` : ''}`);

    static getEventFiles = (eventId: number): Promise<any> => api.apiRequestGet(`planning/event/files?eventId=${eventId}`);
    static putEventSignedFile = (files: PreValidateFile[]): Promise<any> => api.apiRequestPutWithBody(`planning/event/files`, { files });
    static validateEventFiles = (eventId: number, files: { fileId: number, expiryDate?: string; }[]): Promise<any> => api.apiRequestPatchWithBody(`planning/event/files`, { eventId, files });
    static removeEventFile = (eventId: number, fileId: number): Promise<any> => api.apiRequestDelete(`planning/event/files?eventId=${eventId}&fileId=${fileId}`);
    static removeEventFiles = (eventId: number, filesIds: number[]): Promise<any> => api.apiRequestDelete(`planning/event/files?eventId=${eventId}&fileId=${filesIds.join("&fileId=")}`);
    static putEventFile = (files: PreValidateFile[]): Promise<any> => {
        const uploadDynamicFile = (file: File, signed_url: string) => {
            return new Promise<void>((resolve, reject) => {
                const reader = new FileReader();
                 
                reader.onload = (e) => {
                    api.apiRequestFileUpload(signed_url, e.target?.result).then(
                        () => {
                            resolve();
                        },
                        (error) => reject(error),
                    );
                };
                reader.readAsArrayBuffer(file);
            });
        };

        return new Promise<ValidateFile[]>((resolve, reject) => {

            Network.putEventSignedFile(files).then(
                async response => {
                    if (response.error === false && response.data) {
                        const filesToValidate: ValidateFile[] = [];

                        for (let i = 0; i < response.data.length; i++) {
                            const fileData = response.data[i];
                            const file = files.find(f => f.index === fileData.index);
                            if (file !== undefined) {
                                const expiryDate = file.expiryDate;
                                filesToValidate.push({ type: 'ValidateFile', index: file.index, file_name: file.file_name, fileId: Number(fileData.fileId), orgiginalFile: file.file, expiryDate, res_type: file.res_type });
                                try {
                                    await uploadDynamicFile(file.file, fileData.sign_url);
                                } catch (error) {
                                    reject(error);
                                }
                            }

                        }
                        resolve(filesToValidate);
                        //resolve(Network.validateEventFiles(eventId, filesToValidate));
                    } else {
                        reject(response);
                    }
                },
                error => reject(error)
            );
        });
    };

    static getTemplateFiles = (templateId: number, copy = false): Promise<any> => api.apiRequestGet(`planning/template/files?templateId=${templateId}${copy ? '&copy=true' : ''}`);
    static putTemplateSignedFile = (files: PreValidateFile[]): Promise<any> => api.apiRequestPutWithBody(`planning/template/files`, { files });
    static validateTemplateFiles = (templateId: number, files: { fileId: number, expiryDate?: string; }[]): Promise<any> => api.apiRequestPatchWithBody(`planning/template/files`, { templateId, files });
    static removeTemplateFile = (templateId: number, fileId: number): Promise<any> => api.apiRequestDelete(`planning/template/files?templateId=${templateId}&fileId=${fileId}`);
    static removeTemplateFiles = (templateId: number, filesIds: number[]): Promise<any> => api.apiRequestDelete(`planning/template/files?templateId=${templateId}&fileId=${filesIds.join("&fileId=")}`);
    static putTemplateFile = (files: PreValidateFile[]): Promise<any> => {
        const uploadDynamicFile = (file: File, signed_url: string) => {
            return new Promise<void>((resolve, reject) => {
                const reader = new FileReader();
                 
                reader.onload = (e) => {
                    api.apiRequestFileUpload(signed_url, e.target?.result).then(
                        () => {
                            resolve();
                        },
                        (error) => reject(error),
                    );
                };
                reader.readAsArrayBuffer(file);
            });
        };

        return new Promise<ValidateFile[]>((resolve, reject) => {

            Network.putTemplateSignedFile(files).then(
                async response => {
                    if (response.error === false && response.data) {
                        const filesToValidate: ValidateFile[] = [];

                        for (let i = 0; i < response.data.length; i++) {
                            const fileData = response.data[i];
                            const file = files.find(f => f.index === fileData.index);
                            if (file !== undefined) {
                                const expiryDate = file.expiryDate;
                                filesToValidate.push({ type: 'ValidateFile', index: file.index, file_name: file.file_name, fileId: Number(fileData.fileId), orgiginalFile: file.file, expiryDate, res_type: file.res_type });
                                try {
                                    await uploadDynamicFile(file.file, fileData.sign_url);
                                } catch (error) {
                                    reject(error);
                                }
                            }

                        }
                        resolve(filesToValidate);
                        //resolve(Network.validateEventFiles(eventId, filesToValidate));
                    } else {
                        reject(response);
                    }
                },
                error => reject(error)
            );
        });
    };

    static getCompanyUserFiles = (userId: number, copy = false): Promise<any> => api.apiRequestGet(`user/companyuser/files?userId=${userId}${copy ? '&copy=true' : ''}`);
    static putCompanySignedFile = (files: PreValidateFile[]): Promise<any> => api.apiRequestPutWithBody(`user/companyuser/files`, { files });
    static validateCompanyUserFiles = (userId: number, fieldId: number, files: { fileId: number, expiryDate?: string; }[]): Promise<any> => api.apiRequestPatchWithBody(`user/companyuser/files`, { userId, fieldId, files });
    static removeCompanyUserFile = (userId: number, fieldId: number, fileId: number): Promise<any> => api.apiRequestDelete(`user/companyuser/files?userId=${userId}&fieldId=${fieldId}&fileId=${fileId}`);
    static removeCompanyUserFiles = (userId: number, fieldId: number, filesIds: number[]): Promise<any> => api.apiRequestDelete(`user/companyuser/files?userId=${userId}&fieldId=${fieldId}&fileId=${filesIds.join("&fileId=")}`);
    static putCompanyUserFile = (files: PreValidateFile[]): Promise<any> => {
        const uploadDynamicFile = (file: File, signed_url: string) => {
            return new Promise<void>((resolve, reject) => {
                const reader = new FileReader();
                 
                reader.onload = (e) => {
                    api.apiRequestFileUpload(signed_url, e.target?.result).then(
                        () => {
                            resolve();
                        },
                        (error) => reject(error),
                    );
                };
                reader.readAsArrayBuffer(file);
            });
        };

        return new Promise<ValidateFile[]>((resolve, reject) => {

            Network.putCompanySignedFile(files).then(
                async response => {
                    if (response.error === false && response.data) {
                        const filesToValidate: ValidateFile[] = [];

                        for (let i = 0; i < response.data.length; i++) {
                            const fileData = response.data[i];
                            const file = files.find(f => f.index === fileData.index);
                            if (file !== undefined) {
                                const expiryDate = file.expiryDate;
                                filesToValidate.push({ type: 'ValidateFile', index: file.index, file_name: file.file_name, fileId: Number(fileData.fileId), orgiginalFile: file.file, expiryDate, res_type: file.res_type, fieldId: file.fieldId });
                                try {
                                    await uploadDynamicFile(file.file, fileData.sign_url);
                                } catch (error) {
                                    reject(error);
                                }
                            }

                        }
                        resolve(filesToValidate);
                        //resolve(Network.validateEventFiles(eventId, filesToValidate));
                    } else {
                        reject(response);
                    }
                },
                error => reject(error)
            );
        });
    };

    /**
     * Create a user
     * @param body the request's body
     * @returns a promise containing the response of the request
     */
    static getSignedUrlV2 = (bucketName: string, fileName: string): Promise<any> => api.apiRequestGet(`utils/signed-url-v2?bucketName=${bucketName}&fileName=${fileName}`);
    static putSignedUrlV2 = (bucketName: string, fileName: string): Promise<any> => api.apiRequestPut(`utils/signed-url-v2?bucketName=${bucketName}&fileName=${fileName}`);

    static createUser = (body: CreateUserBodyRequest): Promise<any> => {
        const uploadDynamicFile = (fileIndex: number, fileName: string) => {
            return new Promise<void>((resolve, reject) => {
                Network.putSignedUrlV2('sunkhronos_admin', fileName).then(
                    response => {
                        const temp_url = response.sub_url;
                        const signed_url = response.sign_url;

                        const reader = new FileReader();
                        const foundFile = body.files?.at(fileIndex);
                        if (foundFile === undefined) {
                            reject();
                            return;
                        }

                        reader.onload = (e) => {
                            api.apiRequestFileUpload(signed_url, e.target?.result).then(
                                () => {
                                    body.files![fileIndex].file = temp_url;
                                    resolve();
                                },
                                (error) => reject(error),
                            );
                        };
                        reader.readAsArrayBuffer(body.files![fileIndex].file as File);
                    },
                    error => reject(error)
                );
            });
        };

        const uploadImage = () => {
            return new Promise<void>((resolve, reject) => {
                api.apiRequestGet('utils/signed-url').then(
                    response => {

                        body.image = response.sub_url;

                        //compress and upload the image
                        api.compressedImageUpload(body.general.pic, response.sign_url, (status: boolean, content: any) => {
                            if (status) {
                                //final call to create the user
                                resolve();
                            } else {
                                reject(content);
                            }
                        });
                    },
                    error => reject(error)
                );
            });
        };

        return new Promise(async (resolve, reject) => {
            if (body.general.pic && typeof body.general.pic !== "string") {
                try {
                    await uploadImage();
                } catch (error) {
                    console.error("Error uploading image:", error);
                    reject(error);
                }
            }
            if (body.files !== undefined) {
                for (let i = 0; i < body.files.length; i++) {
                    const file = body.files[i];
                    body.files[i].id = -1;
                    console.log("HERE FILE", file)
                    if (file.file !== undefined && typeof file.file !== "string") {
                        try {
                            await uploadDynamicFile(i, file.file.name);
                        } catch (error) {
                            reject(error);
                        }
                    }
                }
            }



            resolve(api.apiRequest('user/create', body));
        });
    };

    /**
     * Delete a user
     * @param user the user to delete
     * @returns a promise containing the response of the request
     */
    static deleteUser = (user: User): Promise<any> => api.apiRequest('user/delete', user);

    static deleteUsers = (usersIds: number[]): Promise<NetworkResponse<string>> => api.apiRequestDeleteWithBody('user/delete-multiple', { usersIds });
    static activateUsers = (usersIds: number[]): Promise<NetworkResponse<User[]>> => api.apiRequest('user/archive', { usersIds, action: true });
    static deactivateUsers = (usersIds: number[]): Promise<NetworkResponse<User[]>> => api.apiRequest('user/archive', { usersIds, action: false });

    static activateReports = (usersIds: number[]): Promise<NetworkResponse<string>> => api.apiRequest('user/report', { usersIds, action: true });
    static deactivateReports = (usersIds: number[]): Promise<NetworkResponse<string>> => api.apiRequest('user/report', { usersIds, action:false });

    /**
     * Update a user password
     * @param userId the user's id
     * @param password the new password
     * @returns a promise containing the response of the request
     */
    static userUpdatePassword = (userId: number, password: string): Promise<any> => api.apiRequest('user/set-password', { user_id: userId, password });

    /**
     * Update a user's groups
     * @param userId the user's id
     * @param groups the actual user's group
     * @param newGroups the new user's group
     * @returns a promise containing the response of the request
     */
    static updateUsersGroups = (userId: number, groups: Group[], newGroups: Group[]): Promise<any> => {
        // filter the newGroups array to keep only the groups that are not already in groups
        const groupsToAdd = newGroups.filter((n: Group) => !groups.find((g: Group) => g.id === n.id));

        //filter the groups array to keep only the groups that leaved the group
        const usersToRemove = groups.filter((g: Group) => !newGroups.find((n: Group) => g.id === n.id));

        return new Promise((resolve, reject) => {
            //add users
            groupsToAdd.forEach(async (g: Group) => {
                const request: UserAssignGroupBody = {
                    user_id: userId,
                    group_id: g.id!,
                    action: "add",
                };

                await api.apiRequest('user/groups-assign', request).then(
                    (reponse) => { resolve(reponse); },
                    () => reject("add")
                );
            });

            //remove users
            usersToRemove.forEach(async (g: Group) => {
                const request: UserAssignGroupBody = {
                    user_id: userId,
                    group_id: g.id!,
                    action: "remove",
                };

                await api.apiRequest('user/groups-assign', request).then(
                    (reponse) => { resolve(reponse); },
                    () => reject("remove")
                );
            });

            resolve(newGroups);
        });
    };

    /* User vacations */

    /**
     * Update the user's yearly vacation days value
     * @param userId the user's id
     * @param days the new value
     * @returns a promise containing the response of the request
     */
    static updateYearlyVacationDays = (userId: number, days: number) => api.apiRequest('user/yearly-vacation-days', { userId, days });

    /**
     * Get the user's vacations summary
     * @param year the concerned year
     * @param userId the concerned user's id
     */
    static getUserVacationsSummary = (year: number, userId?: number) => api.apiRequestGet(`planning/user-vacations-summary?year=${year}${userId ? `&user_id=${userId}` : ''}`);

    /**
     * Get the user's vacations summary
     * @param year the concerned year
     * @param userId the concerned user's id
     */
    static getUserTypesOfDays = (year: number, userId: number) => api.apiRequestGet(`user/type-of-day-off?year=${year}&user_id=${userId}`);
    static deleteUserDaysOffByType = (daysOffByTypeId: number) => api.apiRequestDelete(`user/type-of-day-off?days_off_by_type_id=${daysOffByTypeId}`);
    static createUserDaysOffByType = (daysOffByType: NetworkDaysOffByType) => api.apiRequest('user/type-of-day-off', { daysOffByType });


    /**
     * Get all types of day
     * @param typeOfDayId - a type of day id, if only one type of day must be retrieved - optional
     * @returns a promise containing the response of the request
     */
    static getTypeOfDayOff = (typeOfDayId?: number, userId?: number, year?: number): Promise<any> => api.apiRequestGet(`planning/type-of-day-off${typeOfDayId ? `?type_of_day_id=${typeOfDayId}` : ''}${userId ? `?user_id=${userId}` : ''}${year ? `&year=${year}` : ''}`);

    /**
     * Create or edit a type of day
     * @param typeOfDay - the type of day to create or edit
     * @returns a promise containing the response of the request
     */
    static updateTypeOfDayOff = (typeOfDayOff: NewtworkTypeOfDayOff): Promise<any> => api.apiRequest('planning/type-of-day-off', typeOfDayOff);

    /**
     * Delete a type of day
     * @param typeOfDayId - the id of the type of day to delete
     * @returns a promise containing the response of the request
     */
    static deleteTypeOfDayOff = (typeOfDayId: number): Promise<any> => api.apiRequestDelete(`planning/type-of-day-off${typeOfDayId ? `?type_of_day_id=${typeOfDayId}` : ''}`);


    static getUserYearlyParams = (userId: number, year: number): Promise<any> => api.apiRequestGet(`user/user-yearly-params?user_id=${userId}&year=${year}`);
    static updateUserYearlyParams = (userYearlyParams: NetworkUserYearlyParams): Promise<any> => api.apiRequest('user/user-yearly-params', userYearlyParams);


    static getMonthlyHours = (year: number): Promise<any> => api.apiRequestGet(`planning/monthly-hours-by-year?year=${year}`);
    static updateMonthlyHours = (monthlyHours: MonthlyHoursOfTheYear): Promise<any> => api.apiRequest(`planning/monthly-hours-by-year`, monthlyHours);


    static genHoursByYears = (year: number, weeklyHours: number): Promise<any> => api.apiRequestGet(`planning/generatehours?year=${year}&hours=${weeklyHours}`);

    static getSimpleSetting = (): Promise<any> => api.apiRequestGet(`planning/simple-setting`);
    static updateSimpleSetting = (settings: SimpleSettings): Promise<any> => api.apiRequest(`planning/simple-setting`, settings);


    static getReportSettings = (): Promise<any> => api.apiRequestGet(`planning/report-settings`);
    static updateReportSettings = (settings: ReportSettings): Promise<any> => api.apiRequest(`planning/report-settings`, settings);

    /**
     * Get all types of vacations
     * @returns a promise containing the response of the request
     */
    static getTypeOfVacations = (): Promise<any> => api.apiRequestGet('planning/type-of-vacations');

    /**
     * Get a user's vacations
     * @param from a date from which get the vacations
     * @param to a date to which get the vacations
     * @param userId the concerned user's id - optional
     * @returns a promise containing the response of the request
     */
    static getUserVacations = (from: string, to: string, userId?: number, dayOffExtra?: boolean): Promise<any> => api.apiRequestGet(`planning/user-vacations?from=${from}&to=${to}${userId ? `&user_id=${userId}` : ''}${dayOffExtra ? `&day_off_extra=${dayOffExtra}` : ''}`);

    /**
     * Create or edit a user's vacations
     * @param body the user vacations body request
     * @returns a promise containing the response of the request
     */
    static updateUserVacations = (body: UserVacationsBodyRequest): Promise<any> => api.apiRequest('planning/user-vacations', body);

    /**
     * Delete a user's vacations
     * @param vacationsId the vacations id to delete
     * @returns a promise containing the response of the request
     */
    static deleteUserVacations = (vacationsId: number): Promise<any> => api.apiRequestDelete(`planning/user-vacations?vacations_id=${vacationsId}`);

    /**
     * Delete a user's contract
     * @param contractId the contract (userjob) id to delete
     * @returns a promise containing the response of the request
     */
    static deleteUserContract = (contractId: number): Promise<any> => api.apiRequestDelete(`user/delete-contract?userJobId=${contractId}`);


    static getUserContracts = (userId: number, contractId?: number, year?: number): Promise<any> => api.apiRequestGet(`user/contract?userId=${userId}${contractId ? `&contractId=${contractId}` : ''}${year ? `&year=${year}` : ''}`);

    /**
     * Delete a user's contract
     * @param contractId the contract (userjob) id to delete
     * @returns a promise containing the response of the request
     */
    static updateUserContract = (contract: UserJobTMP): Promise<any> => {
        const create = () => api.apiRequest('user/contract', contract);

        //upload the contract
        if (contract.contract && typeof contract.contract === "object") {

            return new Promise((resolve, reject) => {
                return api.apiRequestGet('utils/signed-url').then(
                    response => {
                        const temp_url = response.sub_url;
                        const signed_url = response.sign_url;

                        const reader = new FileReader();
                        reader.onload = (e) => {
                            api.apiRequestFileUpload(signed_url, e.target?.result).then(
                                () => {
                                    contract.contract = temp_url;
                                    resolve(create());
                                },
                                (error) => reject(error)
                            );
                        };
                        reader.readAsArrayBuffer(contract.contract as File);
                    },
                    (error) => reject(error)
                );
            });
        } else {
            return create();
        }
    };

    /**
     * Get a user's extra vacations
     * @param from a date from which get the vacations
     * @param to a date to which get the vacations
     * @param userId the concerned user's id - optional
     * @returns a promise containing the response of the request
     */
    static getUserExtraVacations = (from: string, to: string, userId?: number): Promise<any> => api.apiRequestGet(`planning/user-extra-vacations?from=${from}&to=${to}${userId ? `&user_id=${userId}` : ''}`);

    /**
     * Create or edit a user's extra vacations
     * @param body the user extra vacations body request
     * @returns a promise containing the response of the request
     */
    static updateUserExtraVacations = (body: UserExtraVacationsBodyRequest): Promise<any> => api.apiRequest('planning/user-extra-vacations', body);

    /**
     * Delete a user's extra vacations
     * @param vacationsId the vacations id to delete
     * @returns a promise containing the response of the request
     */
    static deleteUserExtraVacations = (vacationsId: number): Promise<any> => api.apiRequestDelete(`planning/user-extra-vacations?vacations_id=${vacationsId}`);

    /* User Management (Groups) */

    /**
     * Create a group
     * @param groupName the name of the new group
     * @param userIds the users' ids to assign the group to
     * @returns a promise containing the response of the request
     */
    static createGroup = (groupName: string, userIds: number[]): Promise<any> => {
        return new Promise<any>((resolve, reject) => {
            //create the group with it's name
            api.apiRequest('user/add_group', { name: groupName }).then(
                response => {
                    //if there is no userIds no need to add users
                    if (userIds.length === 0) resolve(response);

                    //add users to group
                    let count = 0;
                    //for each userIds add it to the group
                    userIds.forEach((uId: number) => {
                        const request: UserAssignGroupBody = {
                            user_id: uId,
                            group_id: response.id,
                            action: "add",
                        };
                        //request to add user to a group
                        api.apiRequest('user/groups-assign', request).then(
                            () => {
                                //if it's the last user and all have been added successfully => resolve
                                ++count;
                                if (count === userIds.length) resolve(response);
                            },
                            e => {
                                reject(e);
                            }
                        );
                    });
                },
                e => {
                    reject(e);
                }
            );
        });
    };

    /**
     * Get a group
     * @param groupId the group's id
     * @returns a promise containing the response of the request
     */
    static getGroupUsers = (groupId: number): Promise<any> => api.apiRequestGet(`user/groups/users?gid=${groupId}`);

    /**
     * Update a group's name
     * @param groupdId the group's id
     * @param name the new group's name
     * @returns a promise containing the response of the request
     */
    static updateGroupName = (groupId: number, name: string): Promise<any> => api.apiRequest('user/groups', { name: name, group_id: groupId });


    static updateGroupAdmins = (groupId: number, groupAdminIds: number[]): Promise<any> => api.apiRequest('user/groups', { groupAdminIds: groupAdminIds, group_id: groupId });

    static addUserToGroup = (groupId: number, userId: number): Promise<any> => api.apiRequest('user/groups-assign', {
        user_id: userId,
        group_id: groupId,
        action: "add"
    });

    /**
     * Update a group
     * @param groupdId the group's id
     * @param groupUsers the actual group's users
     * @param newUsers the new group's users
     * @returns a promise containing the response of the request
     */
    static updateGroupUsers = (groupId: number, groupUsers: User[], newUsers: User[]): Promise<any> => {
        // filter the newUsers array to keep only the users that are not already in the group (groupUsers)
        const usersToAdd = newUsers.filter((u: User) => !groupUsers.find((g: User) => g.id === u.id));

        //filter the groupUsers array to keep only the users that leaved the group
        const usersToRemove = groupUsers.filter((g: User) => !newUsers.find((u: User) => g.id === u.id));

        let usersAdded = 0;
        let usersRemoved = 0;
        const usersToAddCount: number = usersToAdd.length;
        const usersToRemoveCount: number = usersToRemove.length;

        return new Promise((resolve, reject) => {
            //add users
            usersToAdd.forEach(async (u: User) => {
                const request: UserAssignGroupBody = {
                    user_id: u.id,
                    group_id: groupId,
                    action: "add",
                };

                await api.apiRequest('user/groups-assign', request).then(
                    () => {
                        usersAdded++;
                        if (usersAdded === usersToAddCount && usersRemoved === usersToRemoveCount) {
                            resolve(newUsers);
                        }
                    },
                    e => reject(e)
                );
            });

            //remove users
            usersToRemove.forEach(async (u: User) => {
                const request: UserAssignGroupBody = {
                    user_id: u.id,
                    group_id: groupId,
                    action: "remove",
                };

                await api.apiRequest('user/groups-assign', request).then(
                    () => {
                        usersRemoved++;
                        if (usersAdded === usersToAddCount && usersRemoved === usersToRemoveCount) {
                            resolve(newUsers);
                        }
                    },
                    (e) => reject(e)
                );
            });


        });
    };

    /**
     * Delete a group
     * @param groupId the group's id to delete
     * @returns a promise containing the response of the request
     */
    static deleteGroup = (groupId: number): Promise<any> => api.apiRequestDelete(`user/groups?group_id=${groupId}`);

    /* Directory Listing */

    /**
     * Get the listing total number of directories
     * @param categroyId the category's id for which get the directories total number - optional
     * @param subCategoryId the sub category's id for which get the directories total number - optional
     * @returns a promise containing the response of the request
     */
    static getListingTotalDirectories = (categoryId?: number, subCategoryId?: number): Promise<any> => api.apiRequestGet(`listing/list/total${categoryId ? `?cid=${categoryId}` : (subCategoryId ? `?scid=${subCategoryId}` : '')}`);

    /**
     * Get the directory listing
     * @param page the number of the page to fetch
     * @param categroyId the category's id for which get the directories - optional
     * @param subCategoryId the sub category's id for which get the directories - optional
     * @returns a promise containing the response of the request
     */
    static getListingDirectories = (page: number, categoryId?: number, subCategoryId?: number): Promise<any> => api.apiRequestGet(`listing/list?page=${page}${categoryId ? `&cid=${categoryId}` : ''}${subCategoryId ? `&scid=${subCategoryId}` : ''}`);

    /**
     * Get the directory listing's categories
     * @param categoryId the category's id, if one category must be retrieved - optional
     * @returns a promise containing the response of the request
     */
    static getListingCategories = (categoryId?: number): Promise<any> => api.apiRequestGet(`listing/category${categoryId ? `?category_id=${categoryId}` : ''}`);

    /**
     * Get a directory listing's sub categories
     * @param categoryId the parent category's id
     * @returns a promise containing the response of the request
     */
    static getListingSubCategories = (categoryId: number): Promise<any> => api.apiRequestGet(`listing/subcategories?ctid=${categoryId}`);

    /**
     * Create a directory listing category
     * @param body the body request
     * @returns a promise containing the response of the request
     */
    static createListingCategory = (body: ListingCategoryBody): Promise<any> => api.apiRequest('listing/category', body);

    /**
     * Create a directory listing category
     * @param body the body request
     * @returns a promise containing the response of the request
     */
    static setCategoryRights = (body: CategoryRightsBody): Promise<any> => api.apiRequest('listing/category/rights', body);

    /**
     * Create a directory listing sub category
     * @param body the body request
     * @returns a promise containing the response of the request
     */
    static createListingSubCategory = (body: ListingSubCategoryBody): Promise<any> => api.apiRequest('listing/subcategories', body);

    /**
     * Edit a directory listing category or sub category
     * @param body the body request
     * @returns a promise containing the response of the request
     */
    static editListingCategory = (body: ListingCategoryBody | ListingSubCategoryBody): Promise<any> => {
        // method to create category
        const create = () => api.apiRequest('listing/categories/add/', [body]);

        if (!body.image || typeof (body.image) === "string") {
            return create();
        } else {
            //otherwise upload the image
            return new Promise((resolve, reject) => {
                //call to get image url
                return api.apiRequestGet('utils/signed-url').then(
                    response => {
                        const temp_url = response.sub_url;
                        const signed_url = response.sign_url;

                        const reader = new FileReader();
                        reader.onload = (e) => {
                            api.apiRequestFileUpload(signed_url, e.target?.result).then(
                                () => {
                                    body.image = temp_url;
                                    resolve(create());
                                },
                                error => reject(error)
                            );
                        };
                        reader.readAsArrayBuffer(body.image);
                    },
                    error => reject(error)
                );
            });
        }
    };

    /**
     * Delete a listing category
     * @returns a promise containing the response of the request
     */
    static deleteListingCategory = (categoryId: number): Promise<any> => api.apiRequestDelete(`listing/category?category_id=${categoryId}`);

    /**
     * Delete a listing sub category
     * @returns a promise containing the response of the request
     */
    static deleteListingSubCategory = (subCategoryId: number): Promise<any> => api.apiRequestDelete(`listing/subcategories?subcategory_id=${subCategoryId}`);

    /**
     * Create a listing directory
     * @param item the listing directory body request
     */
    static createListingDirectory = (item: ListingDirectoryBody): Promise<any> => {
        //final method to create the item
        const create = () => api.apiRequest('listing/create', item);

        if (!item.image && !item.file) {
            return create();
        } else {
            return new Promise(async (resolve, reject) => {
                // method to upload the image
                const uploadImage = async () => {
                    if (!item.image || (typeof (item.image) == 'string' && item.image.startsWith("http"))) {
                        return await api.apiRequest('listing/create', item);
                    } else {
                        //call to get image url
                        return await api.apiRequestGet('utils/signed-url').then(
                            response => {
                                const temp_url = response.sub_url;
                                const signed_url = response.sign_url;

                                const reader = new FileReader();
                                reader.onload = (e) => {
                                    api.apiRequestFileUpload(signed_url, e.target?.result).then(
                                        () => {
                                            item.images = [];
                                            item.images.push(temp_url);
                                            resolve(create());
                                        },
                                        error => reject(error)
                                    );
                                };
                                reader.readAsArrayBuffer(item.image);
                            },
                            error => reject(error)
                        );
                    }
                };

                if (!item.file || (typeof (item.file) == 'string' && item.file.startsWith("http"))) {
                    const tmp = await uploadImage();
                    resolve(tmp);
                } else {
                    const url = 'utils/signed-url?file_name=' + item.file.name;

                    return api.apiRequestGet(url).then(
                        response => {
                            item.files = [];
                            item.files.push(response.sub_url);

                            const reader = new FileReader();
                            let tmp = undefined;
                            reader.onload = (e) => {
                                return api.apiRequestFileUpload(response.sign_url, e.target?.result).then(
                                    async () => {
                                        tmp = await uploadImage();
                                        return tmp;
                                    },
                                    error => reject(error),
                                );
                            };

                            reader.readAsArrayBuffer(item.file);
                            resolve(tmp);
                        },
                        error => reject(error),
                    );
                }
            });
        }
    };

    /**
     * Delete a listing item
     * @param directoyId the directory's id to delete
     * @returns a promise containing the response of the request
     */
    static deleteListingDirectory = (directoyId: number) => api.apiRequestDelete(`listing/delete?directory_id=${directoyId}`);

    /* Emergencies */
    static getEmergencies = (): Promise<NetworkResponse<Emergency>> => api.apiRequestGet('emergency/list');

    static createEmergencyContact = (contact: EmergencyContact): Promise<NetworkResponse<EmergencyContact>> => api.apiRequest(`emergency/contact/`, contact);
    static updateEmergencyContact = (contactId: number, contact: EmergencyContact): Promise<NetworkResponse<EmergencyContact>> => api.apiRequestPatchWithBody(`emergency/contact/${contactId}`, contact);
    static deleteEmergencyContact = (contactId: number): Promise<NetworkResponse<string>> => api.apiRequestDelete(`emergency/contact/${contactId}`);

    static updateEmergencyHeaderText = (text: string): Promise<NetworkResponse<EmergencyHeader>> => api.apiRequest('emergency/update/text', { text });

    static updateEmergencyButton = (name: string, number: string): Promise<NetworkResponse<EmergencyNumber>> => api.apiRequest('emergency/update/button', { name, number });

    /* Administration */

    /* Planning */

    /**
     * Get planning calendar data
     * @param body the body request
     * @returns a promise containing the response of the request
     */
    static getCalendarData = (body: CalendarDataBodyRequest) => api.apiRequest('planning/calendar-data', body);

    /**
     * Get events
     * @param eventId the event's id
     * @returns a promise containing the response of the request
     */
    static getEvents = (eventId?: number): Promise<any> => api.apiRequestGet(`planning/events${eventId !== undefined ? `?event_id=${eventId}` : ''}`);
    static getEventsV2 = (eventId?: number, cut?: boolean): Promise<any> => api.apiRequestGet(`planning/events/v2${eventId !== undefined ? `?event_id=${eventId}` : ''}${cut !== undefined ? `${eventId !== undefined ? '&' : '?'}cut=${cut}` : ''}`);
    static getMultipleEventsV2 = (eventIds: number[], cut?: boolean): Promise<any> => api.apiRequestGet(`planning/events/v2${`?eventIds=${eventIds.join("&eventIds=")}`}${cut !== undefined ? `&cut=${cut}` : ''}`);




    static getEventsImages = (eventId?: number, active?: boolean): Promise<any> => api.apiRequestGet(`planning/events/image${eventId !== undefined ? `?eventId=${eventId}` : ''}${active !== undefined ? `${eventId === undefined ? '?' : '&'}active=${active}` : ''}`);
    static deleteEventsImage = (imageId: number, eventId?: number, active?: boolean): Promise<any> => api.apiRequestDelete(`planning/events/image?imageId=${imageId}${eventId !== undefined ? `&eventId=${eventId}` : ''}${active !== undefined ? `&active=${active}` : ''}`);
    static updateEventsImage = (data: { id: number, name?: string, active?: boolean; }, eventId?: number, active?: boolean): Promise<any> => api.apiRequest(`planning/events/image${eventId !== undefined ? `?eventId=${eventId}` : ''}${active !== undefined ? `${eventId === undefined ? '?' : '&'}active=${active}` : ''}`, data);

    /**
     * Copy a event
     * @param eventId the event's id
     * @param userId the recipient's id
     * @param date the targeted date
     * @param occurenceOnly if only one occurence must be moved - optional
     */
    static copyEvent = (eventId: number, userId: number, date: string, occurenceOnly?: boolean) => api.apiRequest('planning/events/copy', { eventId, userId, date, occurenceOnly });
    static copyPasteEvent = (eventId: number, userId: number, date: string, forceCopy?: boolean) => api.apiRequest('planning/events/copy/paste', { eventId, userId, date, forceCopy: forceCopy ? true : false });
    static copyPasteEvents = (data: CopyEventsBodyRequest[], forceCopy?: boolean) => api.apiRequest('planning/events/multiple/copy/paste',
        {
            data: data.map(d => {
                return {
                    userId: d.userId,
                    eventId: d.eventId,
                    date: d.date.format(MOMENT_FORMAT_DATE_TO_NETWORK),
                };
            }),
            forceCopy: forceCopy ?? false
        }
    );
    static cutPasteEvent = (eventId: number, userId: number, date: string, forceCut?: boolean) => api.apiRequest('planning/events/cut/paste', { eventId, userId, date, forceCopy: forceCut ? true : false });

    /**
     * Get planning vacations and holidays
     * @param from a date from which retrieved vacations/holidays must happen
     * @param to a date until which retrieved vacations/holidays must happen
     */
    static getPlanningVacations = (from: string, to: string) => api.apiRequestGet(`planning/company-vacations?with_holidays=${true}&from=${from}&to=${to}`);

    static getAllTemplates = (): Promise<any> => api.apiRequestGet('planning/templates');

    /**
     * Get all templates
     * @returns a promise containing the response of the request
     */
    static getTemplate = (templateId: number): Promise<NetworkResponse<NetworkEvent>> => api.apiRequestGet(`planning/templates?templateId=${templateId}`);
    static getTemplates = (): Promise<NetworkResponse<NetworkEvent[]>> => api.apiRequestGet('planning/templates');
    static getArchivedTemplates = (): Promise<any> => api.apiRequestGet('planning/templates/archived');


    static getAllIncreasedhoursByDay = (year: number): Promise<any> => api.apiRequestGet(`planning/increased-days?year=${year}`);
    /**
     * Create or edit a template
     * @param template the template to create or edit
     * @returns a promise containing the response of the request
     */
    static updateTemplate = (template: PlanningTemplate): Promise<NetworkResponse<NetworkEvent>> =>  api.apiRequest("planning/templates", convertPlanningTemplateToNetworkTemplate(template));
    
    static archivTemplate = (templateId: number, active: boolean): Promise<any> => {
        return api.apiRequestPatch("planning/templates", { templateId, active });
    };
    static copyTemplate = (eventId: number, template: PlanningTemplate): Promise<any> => {
        return api.apiRequestPutWithBody("planning/templates", convertPlanningTemplateToNetworkTemplate({ eventId, ...template }));
    };

    /**
     * Delete a template
     * @param templateId the id of the template to delete
     * @returns a promise containing the response of the request
     */
    static deleteTemplate = (templateId: number): Promise<any> => {
        return api.apiRequestDelete(`planning/templates?template_id=${templateId}`);
    };

    /**
     * Create or edit an overtime
     * @param overtime the overtime to create or edit
     * @returns a promise containing the response of the request
     */
    static updateOvertime = (overtime: PlanningOvertime): Promise<any> => {
        return api.apiRequest("planning/overtimes", convertPlanningOvertimeToNetworkOvertime(overtime));
    };

    /**
     * Delete an overtime
     * @param overtimeId the id of the overtime to delete
     * @returns a promise containing the response of the request
     */
    static deleteOvertime = (overtimeId: number): Promise<any> => {
        return api.apiRequestDelete(`planning/overtimes?overtime_id=${overtimeId}`);
    };

    /**
     * Get all types of day
     * @param typeOfDayId - a type of day id, if only one type of day must be retrieved - optional
     * @returns a promise containing the response of the request
     */
    static getTypeOfDay = (typeOfDayId?: number): Promise<any> => api.apiRequestGet(`planning/type-of-day${typeOfDayId ? `?type_of_day_id=${typeOfDayId}` : ''}`);

    /**
     * Create or edit a type of day
     * @param typeOfDay - the type of day to create or edit
     * @returns a promise containing the response of the request
     */
    static updateTypeOfDay = (typeOfDay: TypeOfDay): Promise<any> => api.apiRequest('planning/type-of-day', typeOfDay);

    /**
     * Delete a type of day
     * @param typeOfDayId - the id of the type of day to delete
     * @returns a promise containing the response of the request
     */
    static deleteTypeOfDay = (typeOfDayId: number): Promise<any> => api.apiRequestDelete(`planning/type-of-day${typeOfDayId ? `?type_of_day_id=${typeOfDayId}` : ''}`);


    static activateTypeOfDay = (typeOfDayId: number, action = false): Promise<any> => api.apiRequest('planning/archive-type-of-day', { typeOfDayId, action });
    static deactivateTypeOfDay = (typeOfDayId: number, action = true): Promise<any> => api.apiRequest('planning/archive-type-of-day', { typeOfDayId, action });

    /**
     * Get project's events
     * @returns a promise containing the response of the request
     */
    static getProjectEvents = (projectId: number[], startDate: string, endDate: string, departmentId = -1): Promise<any> => api.apiRequestGet(`planning/project-event?startDate=${startDate}&endDate=${endDate}&departmentId=${departmentId}&projectId=${projectId.join("&projectId=")}`);

    /**
     * Get all projects
     * @returns a promise containing the response of the request
     */
    static getProjects = (): Promise<any> => api.apiRequestGet(`planning/project`);

    /**
     * Archive a project
     * @param projectId - the id of the project to archive
     * @returns a promise containing the response of the request
     */
    static archiveProjects = (projectId: number): Promise<any> => api.apiRequest(`planning/project`, {
        action: "archive",
        projectId,
    });

    /**
     * Unarchive a project
     * @param projectId - the id of the project to unarchive
     * @returns a promise containing the response of the request
     */
    static unarchiveProjects = (projectId: number): Promise<any> => api.apiRequest(`planning/project`, {
        action: "unarchive",
        projectId: projectId,
    });

    /**
     * Delete a project
     * @param projectId - the id of the project to delete
     * @returns a promise containing the response of the request
     */
    static deleteProject = (projectId: number): Promise<any> => api.apiRequestDelete(`planning/project?projectId=${projectId}`);

    /**
     * Create or edit a project
     * @param project - the projectId to create or edit
     * @returns a promise containing the response of the request
     */
    static updateProject = (project: Project): Promise<any> => api.apiRequest('planning/project', {
        ...project,
        startDate: project.startDate?.format(MOMENT_FORMAT),
        endDate: project.endDate?.format(MOMENT_FORMAT),
    });

    /**
     * Create an event
     * @param event the event to update
     * @param userIds the event's owners - if they are users
     * @param groups the event's owners - if they are groups
     * @param sendMessage if a message must be sent when creating the event
     * @returns a promise containing the response of the request
     */
    static createEvent = (event: PlanningEvent, userIds: number[], groupIds: number[], sendMessage?: boolean, monthly?: boolean): Promise<any> => {
        const nEvent = convertPlanningEventToNetworkEvent(event);
        return api.apiRequest("planning/events", {
            event: nEvent,
            userIds: userIds.length > 0 ? userIds : undefined,
            groupIds: groupIds.length > 0 ? groupIds : undefined,
            sendMessage,
            monthly
        });
    };

    /**
     * Edit an event
     * @param event the event to update
     * @param occurenceIndex the index of the event to delete - if only one occurence must be deleted - optional
     * @param sendMessage if a message must be sent when creating the event
     * @returns a promise containing the response of the request
     */
    static updateEvent = (event: PlanningEvent, occurenceIndex?: number, sendMessage?: boolean): Promise<any> => {
        const nEvent = convertPlanningEventToNetworkEvent(event);
        return api.apiRequest("planning/events", {
            event: nEvent,
            sendMessage,
            occurenceIndex,
        });
    };

    /**
     * Delete an event
     * @param id the event's id
     * @param occurenceIndex the index of the event to delete - if only one occurence must be deleted - optional
     * @returns a promise containing the response of the request
     */
    static deleteEvent = (id: number, occurenceIndex?: number): Promise<any> => api.apiRequestDelete(`planning/events?event_id=${id}${occurenceIndex !== undefined ? `&occurence_index=${occurenceIndex}` : ''}`);


    /**
     * Create an event
     * @param event the event to update
     * @param userIds the event's owners - if they are users
     * @param groups the event's owners - if they are groups
     * @param sendMessage if a message must be sent when creating the event
     * @returns a promise containing the response of the request
     */
    static createEventV2 = (event: PlanningEvent, userIds: number[], sendMessage?: boolean, forceCreate?: boolean): Promise<any> => {
        const nEvent = convertPlanningEventToNetworkEvent(event);
        return api.apiRequest("planning/events/v2", {
            event: nEvent,
            userIds: userIds,
            sendMessage,
            forceCreate: forceCreate ? true : false
        });
    };

    /**
     * Edit an event
     * @param event the event to update
     * @param occurenceIndex the index of the event to delete - if only one occurence must be deleted - optional
     * @param sendMessage if a message must be sent when creating the event
     * @returns a promise containing the response of the request
     */
    static updateEventV2 = (event: PlanningEvent, sendMessage?: boolean, forceUpdate?: boolean): Promise<any> => {
        const nEvent = convertPlanningEventToNetworkEvent(event);
        return api.apiRequest("planning/events/v2", {
            event: nEvent,
            sendMessage,
            forceUpdate: forceUpdate ? true : false
        });
    };
    /**
     * Delete an event
     * @param id the event's id
     * @param occurenceIndex the index of the event to delete - if only one occurence must be deleted - optional
     * @returns a promise containing the response of the request
     */
    static deleteEventV2 = (ids: number[]): Promise<any> => api.apiRequestDelete(`planning/events/v2?id=${ids.join("&id=")}`);

    /**
     * Get event confirmation state
     * @param id the event's id
     * @returns a promise containing the response of the request
     */
    static eventConfirmed = (id: number): Promise<any> => api.apiRequestGet(`planning/events/confirm?event_id=${id}`);


    static confirmEvent = (eventId: number, userId: number): Promise<any> => api.apiRequest(`planning/events/confirm`, {
        userId: userId,
        eventId: eventId,
        monthly: true
    });

    static confirmEventV2 = (eventId: number, userId: number): Promise<any> => api.apiRequest(`planning/events/confirm/v2`, {
        userId: userId,
        eventId: eventId,
        monthly: true
    });

    static confirmMultipleEvent = (eventsIds: number[]): Promise<any> => api.apiRequest(`planning/events/multipleconfirm`, { eventsIds: eventsIds });

    /**
     * Check if the event happen during the user's or group's periods
     * @param event the event to check
     * @param userIds the list of users' id to check - optional
     * @param groupIds the list of groups' id to check - optional
     */
    static eventCheckAvailability = (event: PlanningEvent, userIds?: number[], groupIds?: number[]): Promise<any> => api.apiRequest(`planning/events/check-availability`, {
        users: userIds,
        groups: groupIds,
        startDate: event.startDate.format(MOMENT_FORMAT),
        endDate: event.endDate.format(MOMENT_FORMAT),
    });

    /**
     * 
     * @param start_date at format MOMENT_FORMAT
     * @param end_date  at format MOMENT_FORMAT
     * @param userIds list of users
     * @returns void
     */
    static eventCheckAvailabilityV2 = (startDate: string, endDate: string, userIds?: number[]): Promise<any> => api.apiRequest(`planning/events/check-availability`, {
        users: userIds,
        startDate: startDate,
        endDate: endDate,
    });
    /**
    * 
    * @param start_date at format MOMENT_FORMAT
    * @param end_date  at format MOMENT_FORMAT
    * @param userIds list of users
    * @returns void
    */
    static eventsCheckAvailabilities = (data: UserAvailabilityBodyRequest[]): Promise<any> => api.apiRequest(`planning/events/check-availabilities`,
        {
            data: data.map(d => {
                return {
                    userId: d.userId,
                    startDate: d.startDate.format(MOMENT_FORMAT_TO_NETWORK),
                    endDate: d.endDate.format(MOMENT_FORMAT_TO_NETWORK),
                };
            })
        }
    );


    static availability = (month: string): Promise<any> => api.apiRequestGet(`planning/availability/v2?month=${month}`);

    /**
     * Get the planning settings
     * @returns a promise containing the response of the request
     */
    static getSettings = (): Promise<any> => api.apiRequestGet('planning/settings');


    /**
     * Create or edit settings
     * @returns a promise containing the response of the request
     */
    static updateSettings = (settings: NetworkSettings): Promise<any> => api.apiRequest("planning/settings", settings);


    /* Periods */

    /**
     * Get all user's or group's periods
     * @param userId the user id, if all periods from a specific user must be retrieved - optional (can be paired with from)
     * @param groupdId the group id, if all periods from a specific group must be retrieved - optional (can be paired with from)
     * @param from a date from which retrieved periods must happen - optional (can be paired with to and userId or groupId)
     * @param to a date to which retrieved periods must happen - optional (can be paired with from and userId or groupId)
     * @returns a promise containing the response of the request
     */
    static getPeriods = (userId?: number, groupId?: number, from?: string, to?: string): Promise<any> => api.apiRequestGet(`planning/periods?${userId ? `user_id=${userId}` : `group_id=${groupId}`}${from ? `&from=${from}` : ''}${to ? `&to=${to}` : ''}`);


    static getPeriodsNotConfirmed = (): Promise<any> => api.apiRequestGet('planning/periods-not-confirmed');
    static getExclusionsNotConfirmed = (): Promise<any> => api.apiRequestGet('planning/exclusions-not-confirmed');
    static refusePeriodNotConfirmed = (periodId: number): Promise<any> => api.apiRequest('planning/periods-not-confirmed', { "query": "refuse", periodId: periodId });
    static validatePeriodNotConfirmed = (periodId?: number, periodsId?: number[]): Promise<any> => api.apiRequest('planning/periods-not-confirmed', { "query": "validate", periodId: periodId, periodsId: periodsId });

    static refuseExclusionNotConfirmed = (periodId: number): Promise<any> => api.apiRequest('planning/exclusions-not-confirmed', { "query": "refuse", periodId: periodId });
    static validateExclusionNotConfirmed = (periodId?: number, periodsId?: number[]): Promise<any> => api.apiRequest('planning/exclusions-not-confirmed', { "query": "validate", periodId: periodId, periodsId: periodsId });

    //#region User Availability

    static getUserAvailabilities = (userId: number, availabilitiesIds?: number[], startDate?: Moment, endDate?: Moment): Promise<any> => {
        return api.apiRequestGet(`planning/user-availability?userId=${userId}${availabilitiesIds ? `&availabilitiesIds=${availabilitiesIds.join("&availabilitiesIds=")}` : ''}${startDate ? `&startDate=${startDate.format(MOMENT_FORMAT_DATE_TO_NETWORK)}` : ''}${endDate ? `&endDate=${endDate.format(MOMENT_FORMAT_DATE_TO_NETWORK)}` : ''}`);
    };

    static editUserAvailability = (availability: UserAvailabilityEditNetwork) => api.apiRequest('planning/user-availability', availability);


    static deleteUserAvailability = (availabilitiesIds: number[]): Promise<any> => api.apiRequestDelete(`planning/user-availability?ids=${availabilitiesIds}`);


    static editUserAvailabilityDefault = (userAvailabilityDefault: UserAvailabilityDefault) => api.apiRequest('planning/user-availability/default', userAvailabilityDefault);

    static getTeamAvailabilitiesNotConfirmed = (): Promise<any> => api.apiRequestGet('planning/team-availabilities/not-confirmed');
    static refuseTeamAvailabilitiesNotConfirmed = (availabilityId: number): Promise<any> => api.apiRequest('planning/team-availabilities/not-confirmed', { "query": "refuse", availabilityId: availabilityId });
    static validateTeamAvailabilitiesNotConfirmed = (availabilityId?: number, availabilitiesIds?: number[]): Promise<any> => api.apiRequest('planning/team-availabilities/not-confirmed/', { "query": "validate", availabilityId: availabilityId, availabilitiesIds: availabilitiesIds });


    //#endregion

    /**
     * Create or edit a period
     * @param period the period to update
     * @returns a promise containing the response of the request
     */
    static updatePeriod = (period: PlanningPeriod): Promise<any> => {
        const nPeriod = convertPlanningPeriodToNetworkPeriod(period);
        nPeriod.group = nPeriod.user = undefined;
        return api.apiRequest('planning/periods', nPeriod);
    };

    /**
     * Delete a period
     * @returns a promise containing the response of the request
     */
    static deletePeriod = (id: number): Promise<any> => api.apiRequestDelete(`planning/periods?period_id=${id}`);


    /* Exclusions */

    /**
     * Get all user's or group's exclusions
     * @param userId the user id, if all exclusions from a specific user must be retrieved - optional (can be paired with from)
     * @param groupdId the group id, if all exclusions from a specific group must be retrieved - optional (can be paired with from)
     * @param from a date from which retrieved exclusions must happen - optional (can be paired with to and userId or groupId)
     * @param to a date to which retrieved exclusions must happen - optional (can be paired with from and userId or groupId)
     * @returns a promise containing the response of the request
     */
    static getExclusions = (userId?: number, groupId?: number, from?: string, to?: string): Promise<any> => api.apiRequestGet(`planning/exclusions?${userId ? `user_id=${userId}` : `group_id=${groupId}`}${from ? `&from=${from}` : ''}${to ? `&to=${to}` : ''}`);

    /**
     * Create or edit an exclusion
     * @param exclusion the exclusion to update
     * @param checkEvents to check if an event happen during the exclusion - optional
     * @returns a promise containing the response of the request
     */
    static updateExclusion = (exclusion: PlanningExclusion, checkEvents?: boolean): Promise<any> => {
        const nExclusion = convertPlanningExclusionToNetworkExclusion(exclusion);
        nExclusion.group = nExclusion.user = undefined;
        return api.apiRequest('planning/exclusions', { checkEvents: Boolean(checkEvents), exclusion: nExclusion });
    };


    static setUserJobExpirationExclusion = (userJobId: number): Promise<any> => api.apiRequest('user/expiration-contract/', { userJobId });

    /**
     * Delete an exclusion
     * @returns a promise containing the response of the request
     */
    static deleteExclusion = (id: number): Promise<any> => api.apiRequestDelete(`planning/exclusions?exclusion_id=${id}`);

    /**
     * Get a user's effective periods
     * @param userId the user's id - optional
     * @param groupId the group's id - optional
     * @returns a promise containing the response of the request
     */
    static getEffectivePeriods = (userId?: number, groupId?: number): Promise<any> => api.apiRequestGet(`planning/effective-periods?${userId ? `user_id=${userId}` : `group_id=${groupId}`}`);

    /* Configurations */

    /**
     * Get occupancy rates
     * @param occupancyRateId the occupancy rate's id, if only one occupancy rate must be retrieved - optional
     * @returns a promise containing the response of the request
     */
    static getOccupancyRates = (occupancyRateId?: number, poiId?: number): Promise<any> => api.apiRequestGet(`planning/occupancy-rate${occupancyRateId ? `?occupancy_rate_id=${occupancyRateId}` : ''}${poiId ? `${occupancyRateId ? "&" : "?"}poiId=${poiId}` : ''}`);

    /**
     * Create or edit an occupancy rate
     * @param occupancyRate the occupancy rate request body
     * @returns a promise containing the response of the request
     */
    static updateOccupancyRate = (occupancyRate: OccupancyRateBodyRequest): Promise<any> => api.apiRequest('planning/occupancy-rate', occupancyRate);

    /**
     * Delete an occupancy rate
     * @param occupancyRateId the occupancy rate's id to delete
     * @returns a promise containing the response of the request
     */
    static deleteOccupancyRate = (occupancyRateId: number): Promise<any> => api.apiRequestDelete(`planning/occupancy-rate?occupancy_rate_id=${occupancyRateId}`);

    /**
     * Get occupancy rates exclusions
     * @param exclusionId the exclusion's id, if only one exclusion must be retrieved - optional
     * @returns a promise containing the response of the request
     */
    static getOccupancyRateExclusions = (exclusionId?: number): Promise<any> => api.apiRequestGet(`planning/occupancy-rate-exclusion${exclusionId ? `?exclusion_id=${exclusionId}` : ''}`);

    /**
     * Create or edit an occupancy rate exclusion
     * @param exclusion the exclusion to update
     * @returns a promise containing the response of the request
     */
    static updateOccupancyRateExclusion = (exclusion: NetworkOccupancyRateExclusion): Promise<any> => api.apiRequest('planning/occupancy-rate-exclusion', exclusion);

    /**
     * Delete an occupancy rate exclusion
     * @param exclusionId the exclusion's id to delete
     * @returns a promise containing the response of the request
     */
    static deleteOccupancyRateExclusion = (exclusionId: number): Promise<any> => api.apiRequestDelete(`planning/occupancy-rate-exclusion?exclusion_id=${exclusionId}`);


    /**
     * Get holidays
     * @returns a promise containing the response of the request
     */
    static getHolidays = (): Promise<any> => api.apiRequestGet('planning/holidays');

    /**
     * Get vacations
     * @returns a promise containing the response of the request
     */
    static getVacations = (): Promise<any> => api.apiRequestGet('planning/company-vacations');

    /**
     * 
     * @param month month format MOMENT_MONTH_FORMAT
     * @returns 
     */
    static getAllHolidays = (startDate: string, endDate: string): Promise<any> => api.apiRequestGet(`planning/allholidays?startDate=${startDate}&endDate=${endDate}`);

    /**
     * Create or edit vacations
     * @param vacations the vacations to update
     * @returns a promise containing the response of the request
     */
    static updateVacations = (vacations: NetworkVacations): Promise<any> => api.apiRequest('planning/company-vacations', vacations);

    /**
     * Delete vacations
     * @param vacationsId the id of the vacations to delete
     * @returns a promise containing the response of the request
     */
    static deleteVacations = (vacationsId: number): Promise<any> => api.apiRequestDelete(`planning/company-vacations?vacations_id=${vacationsId}`);


    /**
     * Get Type Of Vehicles
     * @returns a promise containing the response of the request
     */
    static getTypeOfVehicles = (): Promise<any> => api.apiRequestGet('location/type-of-vehicle');

    /**
     * Create or edit Type Of Vehicles
     * @returns a promise containing the response of the request
     */
    static updateTypeOfVehicles = (typeOfVehicle: NetworkTypeOfVehicle): Promise<any> => api.apiRequest('location/type-of-vehicle', typeOfVehicle);

    /**
     * Delete Type Of Vehicles
     * @param typeOfVehicleId the id of the Type Of Vehicles to delete
     * @returns a promise containing the response of the request
     */
    static deleteTypeOfVehicle = (typeOfVehicleId: number): Promise<any> => api.apiRequestDelete(`location/type-of-vehicle?id=${typeOfVehicleId}`);

    /**
         * Get Type Of Contract
         * @returns a promise containing the response of the request
         */
    static getDepartments = (): Promise<any> => api.apiRequestGet('user/departments');


    /**
     * Get Type Of Contract
     * @returns a promise containing the response of the request
     */
    static getTypeOfContract = (): Promise<any> => api.apiRequestGet('user/type-of-contract');

    /**
     * Create or edit Type Of Contract
     * @returns a promise containing the response of the request
     */
    static updateTypeOfContract = (typeOfContract: NetworkTypeOfContract): Promise<any> => api.apiRequest('user/type-of-contract', typeOfContract);

    /**
     * Delete Type Of Contract
     * @param typeOfContractId the id of the Type Of Contract to delete
     * @returns a promise containing the response of the request
     */
    static deleteTypeOfContract = (typeOfContractId: number): Promise<any> => api.apiRequestDelete(`user/type-of-contract?id=${typeOfContractId}`);


    /**
     * Get Majoration
     * @returns a promise containing the response of the request
     */
    static getMajoration = (): Promise<any> => api.apiRequestGet('planning/increased-days');


    /**
     * Create or edit Majoration
     * @returns a promise containing the response of the request
     */
    static updateMajoration = (majoration: NetworkMajoration): Promise<any> => api.apiRequest('planning/increased-days', majoration);

    /**
     * Delete Majoration
     * @param majorationId the id of the Majoration to delete
     * @returns a promise containing the response of the request
     */
    static deleteMajoration = (majorationId: number): Promise<any> => api.apiRequestDelete(`planning/increased-days?id=${majorationId}`);



    /* Monthly Planning */

    /**
     * Get monthly planning
     * @param month the date of the first day of the month to get
     * @param displayWorkingTime if only working time must be displayed
     * @returns a promise containing the response of the request
     */
    static getMonthlyPlanning = (month: string, displayWorkingTime: boolean, confirmation: number, dataType: number, groupId?: number[], userIds?: number[]): Promise<any> => api.apiRequestGet(`planning/monthly-planning?month=${month}&display_working_time=${displayWorkingTime}&confirmation=${confirmation}&data_type=${dataType}${groupId ? `&group_id=${groupId.join("&group_id=")}` : ''}${userIds ? `&user_ids=${userIds.join("&user_ids=")}` : ''}&version=beta`);


    static getMonthlyPlanningV2 = (month: string): Promise<any> => api.apiRequestGet(`planning/monthly-planning/v2?month=${month}`);
    static getPlanning = (startDate: string, endDate: string): Promise<any> => api.apiRequestGet(`planning/data?startDate=${startDate}&endDate=${endDate}`);
    static getPlanningStats = (startDate: string, endDate: string): Promise<any> => api.apiRequestGet(`planning/statistics?startDate=${startDate}&endDate=${endDate}`);
    static getPlanningAvailabilities = (startDate: string, endDate: string): Promise<any> => api.apiRequestGet(`planning/availabilities?startDate=${startDate}&endDate=${endDate}`);
    static getPlanningUnavailabilities = (startDate: string, endDate: string): Promise<any> => api.apiRequestGet(`planning/unavailabilities?startDate=${startDate}&endDate=${endDate}`);


    /* Report */

    /**
     * Get user month report
     * @param month the month for which generate the reports
     * @param userIds the ids of the users for which generate the report, if this parameters is not specified generate reports for all users - optional
     * @returns a promise containing the response of the request
     */
    static generateReports = (month: string, userIds?: number[]): Promise<any> => api.apiRequestFile('planning/user-reports', { month, userIds });

    /**
     * Get user month report
     * @param month the month for which generate the reports
     * @param userIds the ids of the users for which generate the report, if this parameters is not specified generate reports for all users - optional
     * @returns a promise containing the response of the request
     */
    // static generateReportsv2 = (startMonth: string, endMonth: string, userIds?: number[], jobIds?: number[]): Promise<any> => api.apiRequestFile('planning/user-reportsv2', { startMonth, endMonth, userIds, jobIds });
    static generateReportsOld = (startMonth: string, endMonth: string, userIds?: number[], jobIds?: number[]): Promise<any> => api.apiRequestFile('planning/user-reportsv2', { startMonth, endMonth, userIds, jobIds });

    static generateReportsNormal = (startMonth: string, endMonth: string, userIds?: number[], jobIds?: number[]): Promise<any> => api.apiRequestGetWithBody('planning/report-normal', { startMonth, endMonth, userIds, jobIds });

    static generateReportsCcnt = (startMonth: string, endMonth: string, userIds?: number[], jobIds?: number[]): Promise<any> => api.apiRequestGetWithBody('planning/report-hotel-catering', { startMonth, endMonth, userIds, jobIds });
    static generateReportsCcntV2 = (startMonth: string, endMonth: string, userIds?: number[], jobIds?: number[]): Promise<any> => api.apiRequestGetWithBody('planning/report-hotel-catering-v2', { startMonth, endMonth, userIds, jobIds });
    static generateReportsCcntAccounting = (startMonth: string, endMonth: string, userIds?: number[], departmentId?: number): Promise<any> => api.apiRequestGetWithBody('planning/report-hotel-catering/accounting', { startMonth, endMonth, userIds, departmentId });

    static generateReportsCctSecurity = (startMonth: string, endMonth: string, userIds?: number[], jobIds?: number[], departmentId?: number): Promise<any> => api.apiRequestGetWithBody('planning/report-security', { startMonth, endMonth, userIds, jobIds, departmentId });


    static userReportsSummary = (year: number): Promise<any> => api.apiRequest('planning/user-reports-summary', { year });
    static usersHoursSummary = (year: number): Promise<NetworkUserHoursSummaries> => api.apiRequest('planning/user-reports-summary-v2', { year });
    static userHoursSummaryWithContractDetails = (userId: number, year: number): Promise<NetworkUserHoursSummariesWithContractDetails> => api.apiRequest('planning/user-report-contracts-details', { year, userId });

    /**
     * Get company month report
     * @param month the month for which generate the reports
     * @returns a promise containing the response of the request
     */
    static generateCompanyReport = (month: string, withHours = true, departmentId = -1): Promise<any> => api.apiRequestFile('planning/company-reports', { month, withHours, departmentId });

    /* Swisskischool */

    /**
     * Get school clients
     * @param page the page number, if the clients must be retrieve by page - optional
     * @param search the search, if the clients must be retrieve with a search - optional
     * @returns a promise containing the response of the request
     */
    static getSchoolClients = (page?: number, search?: string, pageSize?: number, onlyFromSkiResort?: boolean): Promise<any> => {
        let request = "";
        const pageRequest = page !== undefined ? page : null;
        const searchRequest = search ? search : null;
        const pageSizeRequest = pageSize !== undefined ? pageSize : null;
        const onlyFromSkiResortRequest = onlyFromSkiResort !== undefined ? onlyFromSkiResort : null;
        if (pageRequest !== null) {
            request += `?page=${pageRequest}`;
        }

        if (searchRequest !== null) {
            request += `${pageRequest !== null ? '&' : '?'}search=${searchRequest}`;
        }

        if (pageSizeRequest !== null) {
            request += `${pageRequest !== null || searchRequest !== null ? '&' : '?'}pageSize=${pageSizeRequest}`;
        }

        if (onlyFromSkiResortRequest !== null) {
            request += `${pageRequest !== null || searchRequest !== null || pageSizeRequest !== null ? '&' : '?'}onlyFromSkiResort=${onlyFromSkiResortRequest}`;
        }

        return api.apiRequestGet(`client/school-clients${request}`);
    };

    /**
     * Save modifications 
     * @returns a promise containing the response of the request
     */
    static saveCustomerLoyalty = (customerLoyalty: CustomerLoyalty): Promise<any> => api.apiRequest("client/customer-loyalty-admin", customerLoyalty);

    /**
     * Get total school clients
     * @returns a promise containing the response of the request
     */
    static getTotalSchoolClients = (): Promise<any> => api.apiRequestGet(`client/school-clients/total`);

    /**
     * Get school medals
     * @returns a promise containing the response of the request
     */
    static getBCMedals = (): Promise<any> => api.apiRequestGet(`client/medals?lang=fr`);

    /**
     * Get school medals
     * @returns a promise containing the response of the request
     */
    static getBCLoadClients = (body: LoadClientBodyRequest): Promise<any> => api.apiRequest(`client/load-client`, {
        ...body,
        From: body.From.format('YYYY/MM/DDTHH:mm:ss'),
        To: body.To.format('YYYY/MM/DDTHH:mm:ss')
    });

    static getBCLoadLessons = (language: string, fromDate: Moment, toDate: Moment): Promise<any> => api.apiRequestGet(`client/active-lessons?language=${language}&fromDate=${fromDate.format(MOMENT_FORMAT_TO_NETWORK)}&toDate=${toDate.format(MOMENT_FORMAT_TO_NETWORK)}`);

    /**
     * Get BC countries
     * @returns a promise containing the response of the request
     */
    static getBCCountries = (): Promise<any> => api.apiRequestGet(`client/countries?lang=fr`);

    /**
     * Get BC languages
     * @returns a promise containing the response of the request
     */
    static getBCLanguages = (): Promise<any> => api.apiRequestGet(`client/languages?lang=fr`);

    /**
     * Get promotions
     * @returns a promise containing the response of the request
     */
    static getPromotions = (): Promise<any> => api.apiRequestGet(`client/promotion`);

    /**
     * CreatePromotion
     * @param promotion the body of a promotion
     * @returns a promise containing the response of the request
     */
    static createPromotion = (promotion: PromotionBodyRequest): Promise<any> => api.apiRequest(`client/promotion`, promotion);

    static getClientMessages = (): Promise<any> => api.apiRequestGet(`client/messages`);
    static createClientMessages = (body: MailBodyRequest | SMSBodyRequest): Promise<any> => api.apiRequest(`client/messages`, body);


    /**
      * Save modifications 
      * @returns a promise containing the response of the request
      */
    static saveAllPoisModifications = (poi: POI[]): Promise<any> => api.apiRequest("location/poi/save", poi);

    static convertPoiToBasePoi = (poiIds: number[]): Promise<any> => api.apiRequest("location/convert/poi/basepoi", { poiIds });
    static convertBasePoiToPoi = (poiIds: number[]): Promise<any> => api.apiRequest("location/convert/basepoi/poi", { poiIds });
    static duplicatePoiToBasePoi = (poiIds: number[], force: boolean): Promise<any> => api.apiRequest("location/duplicate/poi/basepoi", { poiIds, force });
    static duplicateBasePoiToPoi = (poiIds: number[], force: boolean): Promise<any> => api.apiRequest("location/duplicate/basepoi/poi", { poiIds, force });
    static quickAssignPoi = (segmentedValue: SegmentedValue, poiIds: number[], sectorsIds: number[], groupsIds: number[], departmentId?: number): Promise<any> => api.apiRequest(`location/poi/${segmentedValue === 'remove' ? 'unassign' : 'assign'}`, { poiIds, sectorsIds, groupsIds, departmentId });
    /**
     * Get poi => if poiId get one, else get all
     * @returns a promise containing the response of the request
     */
    static getPoi = (poiId?: number): Promise<any> => api.apiRequestGet(`location/poi${poiId ? `?poiId=${poiId}` : ''}`);


    static getCustomers = (): Promise<NetworkResponse<ICustomerNetwork[]>> => api.apiRequestGet(`crm/customers`);
    static getCustomer = (customerId: number): Promise<NetworkResponse<ICustomerNetwork>> => api.apiRequestGet(`crm/customer?customerId=${customerId}`);
    static getCustomersStats = (force = false): Promise<NetworkResponse<ICustomerStat[]>> => api.apiRequestGet(`crm/customers/stats?force=${force}`);

    static getCustomerMandates = (customerId: number): Promise<NetworkResponse<IMandate[]>> => api.apiRequestGet(`crm/customer/mandates?customerId=${customerId}`);
    static getMandates = (): Promise<NetworkResponse<IMandate[]>> => api.apiRequestGet(`crm/mandates`);
    static getMandate = (mandateId: number): Promise<NetworkResponse<IMandate>> => api.apiRequestGet(`crm/mandate?mandateId=${mandateId}`);


    static getMandateMissions = (mandateId: number): Promise<NetworkResponse<IMission[]>> => api.apiRequestGet(`crm/mandate/missions?mandateId=${mandateId}`);
    static getCustomerMissions = (customerId: number): Promise<NetworkResponse<IMission[]>> => api.apiRequestGet(`crm/customer/missions?customerId=${customerId}`);
    static getMissions = (): Promise<NetworkResponse<IMission[]>> => api.apiRequestGet(`crm/missions`);
    static getMission = (missionId: number): Promise<NetworkResponse<IMission>> => api.apiRequestGet(`crm/mission?missionId=${missionId}`);
    static deleteMission = (missionId: number): Promise<NetworkResponse<IMission>> => api.apiRequestDelete(`crm/mission?missionId=${missionId}`);
    static updateMission = (mission: IMissionEdit, mandateId: number): Promise<NetworkResponse<IMission>> => {
        const endpoint = mission.id && mission.id > 0 ? api.apiRequestPatch : api.apiRequestPut;
        const url = mission.id && mission.id > 0 ? `crm/mission?missionId=${mission.id}` : `crm/mission?mandateId=${mandateId}`;
        return endpoint(url, {
            ...mission,
            startDate: moment(mission.startDate).format(MOMENT_FORMAT_DATE_TO_NETWORK),
            endDate: moment(mission.endDate).format(MOMENT_FORMAT_DATE_TO_NETWORK),
            rules: mission.rules?.map(rule => {
                return {
                    ...rule,
                    template: rule.template ? convertPlanningTemplateToNetworkTemplate(rule.template) : undefined,
                    startDate: moment(rule.startDate).format(MOMENT_FORMAT_DATE_TO_NETWORK),
                    endDate: moment(rule.endDate).format(MOMENT_FORMAT_DATE_TO_NETWORK)
                }
            })
        });
    }
    static updateMissionRule = (missionRule: IMissionRuleEdit, missionId: number): Promise<NetworkResponse<IMissionRule>> => api.apiRequestPut(`crm/mission/rule?missionId=${missionId}`, {
        ...missionRule,
        startDate: moment(missionRule.startDate).format(MOMENT_FORMAT_DATE_TO_NETWORK),
        endDate: moment(missionRule.endDate).format(MOMENT_FORMAT_DATE_TO_NETWORK)
    });

    static getCustomerSites = (customerId: number): Promise<NetworkResponse<ISite[]>> => api.apiRequestGet(`crm/customer/sites?customerId=${customerId}&all=${true}`);
    static getSites = (): Promise<NetworkResponse<ISite[]>> => api.apiRequestGet(`crm/sites`);
    static getSite = (siteId: number): Promise<NetworkResponse<ISite>> => api.apiRequestGet(`crm/site?siteId=${siteId}`);


    static getCRMTemplates = (): Promise<any> => api.apiRequestGet('crm/templates');

    static getMissionStatus = (startDate: Moment, endDate: Moment): Promise<NetworkResponse<IMissionStatus[]>> => api.apiRequestGet(`planning/events/missions/statut?startDate=${startDate.format(MOMENT_FORMAT_DATE_TO_NETWORK)}&endDate=${endDate.format(MOMENT_FORMAT_DATE_TO_NETWORK)}`);
    static getEventFromTemplate = (templateId: number, startDate: Moment): Promise<NetworkResponse<NetworkEvent>> => api.apiRequestGet(`planning/template/event?templateId=${templateId}&startDate=${startDate.format(MOMENT_FORMAT_DATE_TO_NETWORK)}`);

    static getAggregatedEvents = (startDate: Moment, endDate: Moment): Promise<NetworkResponse<IAggregatedEvent[]>> => api.apiRequestGet(`planning/aggregated-events?startDate=${startDate.format(MOMENT_FORMAT_DATE_TO_NETWORK)}&endDate=${endDate.format(MOMENT_FORMAT_DATE_TO_NETWORK)}`);
    static getAggregatedEventsForGlobalOfficeShifts = (startDate: Moment, endDate: Moment): Promise<NetworkResponse<IAggregatedEvent[]>> => api.apiRequestGet(`planning/aggregated-events?startDate=${startDate.format(MOMENT_FORMAT_DATE_TO_NETWORK)}&endDate=${endDate.format(MOMENT_FORMAT_DATE_TO_NETWORK)}&related=globaloffice`);

    static setAggregatedEvents = (agregatedIds: number[]): Promise<NetworkResponse<IAggregatedEvent[]>> => api.apiRequestPatchWithBody('planning/aggregated-events', { agregatedIds: agregatedIds });
    /**
     * Create a Poi
     * @returns a promise containing the response of the request
     */
    static createPoi = (poi: POI): Promise<any> => {
        const create = () => api.apiRequest("location/poi", poi);


        if (poi.image && typeof (poi.image) !== "string") {
            return new Promise(async (resolve, reject) => {
                api.apiRequestGet('utils/signed-url').then(
                    response => {
                        const temp_url = response.sub_url;
                        const signed_url = response.sign_url;

                        const compress = new Compress();
                        compress.compress([poi.image], {
                            size: 2, // the max size in MB, defaults to 2MB
                            quality: .75, // the quality of the image, max is 1,
                            maxWidth: 1200, // the max width of the output image, defaults to 1920px
                            resize: true, // defaults to true, set false if you do not want to resize the image width and height
                        }).then((data: any) => {
                            const img1 = data[0];
                            const base64str = img1.data;
                            const imgExt = img1.ext;
                            const file = Compress.convertBase64ToFile(base64str, imgExt);


                            // return null;
                            const reader = new FileReader();
                            reader.onload = (e) => {
                                api.apiRequestFileUpload(signed_url, e.target?.result).then(
                                    () => {
                                        poi.image = temp_url;
                                        resolve(create());
                                    },
                                    error => reject(error)
                                );
                            };
                            reader.readAsArrayBuffer(file);
                        });
                    },
                    error => reject(error)
                );
            });
        } else {
            return create();
        }
    };

    /**
      * Update a Poi
      * @returns a promise containing the response of the request
      */
    static updatePoi = (poi: POI): Promise<any> => {
        const create = () => api.apiRequest("location/poi", poi);

        if (poi.image && typeof (poi.image) === "string" && poi.image.includes("https://storage.googleapis.com/")) {
            poi.image = poi.image.replace("https://storage.googleapis.com/", "");
        }

        if (poi.image && typeof (poi.image) !== "string") {
            return new Promise(async (resolve, reject) => {
                api.apiRequestGet('utils/signed-url').then(
                    response => {
                        const temp_url = response.sub_url;
                        const signed_url = response.sign_url;

                        const compress = new Compress();
                        compress.compress([poi.image], {
                            size: 2, // the max size in MB, defaults to 2MB
                            quality: .75, // the quality of the image, max is 1,
                            maxWidth: 1200, // the max width of the output image, defaults to 1920px
                            resize: true, // defaults to true, set false if you do not want to resize the image width and height
                        }).then((data: any) => {
                            const img1 = data[0];
                            const base64str = img1.data;
                            const imgExt = img1.ext;
                            const file = Compress.convertBase64ToFile(base64str, imgExt);


                            // return null;
                            const reader = new FileReader();
                            reader.onload = (e) => {
                                api.apiRequestFileUpload(signed_url, e.target?.result).then(
                                    () => {
                                        poi.image = temp_url;
                                        resolve(create());
                                    },
                                    error => reject(error)
                                );
                            };
                            reader.readAsArrayBuffer(file);
                        });
                    },
                    error => reject(error)
                );
            });
        } else {
            return create();
        }
    };


    /**
      * Delete one or more POIs
      * @returns a promise containing the response of the request
      */
    static deletePoi = (poiId: number[]): Promise<any> => api.apiRequestDelete(`location/poi?poiId=${poiId.join("&poiId=")}`);


    /**
     * Get course => if courseId get one, else get all
     * @returns a promise containing the response of the request
     */
    static getCourse = (displayType = "long", courseId?: number): Promise<any> => api.apiRequestGet(`location/course?displayType=${displayType}${courseId ? `&courseId=${courseId}` : ''}`);

    /**
     * Create a Course
     * @returns a promise containing the response of the request
     */
    static createCourse = (course: Course): Promise<any> => api.apiRequest("location/course", course);

    /**
      * Update a Course
      * @returns a promise containing the response of the request
      */
    static updateCourse = (course: Course): Promise<any> => api.apiRequest("location/course", course);

    /**
      * Delete one or more courses
      * @returns a promise containing the response of the request
      */
    static deleteCourse = (courseId: number[]): Promise<any> => api.apiRequestDelete(`location/course?courseId=${courseId.join("&courseId=")}`);

    /**
     * Get sector => if sectorId get one, else get all
     * @returns a promise containing the response of the request
     */
    static getSector = (sectorId?: number): Promise<any> => api.apiRequestGet(`location/sector${sectorId ? `sectorId=${sectorId}` : ''}`);

    /**
     * Create a Sector
     * @returns a promise containing the response of the request
     */
    static createSector = (sector: Sector): Promise<any> => api.apiRequest("location/sector", sector);

    /**
      * Update a Sector
      * @returns a promise containing the response of the request
      */
    static updateSector = (sector: Sector): Promise<any> => api.apiRequest("location/sector", sector);

    /**
      * Delete one or more sectors
      * @returns a promise containing the response of the request
      */
    static deleteSector = (sectorId: number[]): Promise<any> => api.apiRequestDelete(`location/sector?sectorId=${sectorId.join("&sectorId=")}`);

    static generateDistance = (fromLat: string, fromLong: string, toLat: string, toLong: string): Promise<any> => api.apiRequestGet(`location/generate-distance?fromLat=${fromLat}&fromLong=${fromLong}&toLat=${toLat}&toLong=${toLong}`);

    /**
     * Get poi => if poiId get one, else get all
     * @returns a promise containing the response of the request
     */
    static getStaffType = (staffTypeId?: number): Promise<any> => api.apiRequestGet(`user/staff-type${staffTypeId ? `?staffTypeId=${staffTypeId}` : ''}`);

    /**
      * Delete one or more staff types
      * @returns a promise containing the response of the request
      */
    static deleteStaffType = (staffTypeId: number[]): Promise<any> => api.apiRequestDelete(`user/staff-type?staffTypeId=${staffTypeId.join("&staffTypeId=")}`);

    /**
     * Create a Staff Type
     * @returns a promise containing the response of the request
     */
    static createStaffType = (staffType: StaffType): Promise<any> => api.apiRequest("user/staff-type", staffType);

    /**
      * Update a Staff Type
      * @returns a promise containing the response of the request
      */
    static updateStaffType = (staffType: StaffType): Promise<any> => api.apiRequest("user/staff-type", staffType);


    /**
     * Get sector => if sectorId get one, else get all
     * @returns a promise containing the response of the request
     */
    static getSunkhronosLive = (day: string): Promise<any> => api.apiRequestGet(`location/poi/live?day=${day}`);


    static getPaidHoursSummary = (year: number): Promise<any> => api.apiRequestGet(`planning/paid-hours-summary?year=${year}`);
    static setPaidHours = (userId: number, contractId: number, year: number, month: number, hours: number): Promise<any> => api.apiRequest(`planning/paid-hours-simple`, {
        userId, contractId, year, month, hours
    });


    /**
     * Get event clockeds
     * @param running Specified running state (all | running | paused  | stopped) => 'all' by default
     * @param state Specified state (all | confirmed | rejected | waiting-confirmation) => 'all' by default
     * @returns a promise containing the response of the request
     */
    static getEventClocked = (eventClockedId: number, running?: string, state?: string): Promise<any> => api.apiRequestGet(`timeclock/event-clocked/${eventClockedId}/${running ? running : 'all'}/${state ? state : 'all'}`);
    static getEventClockeds = (displayOnDates: RangeValue<Moment>, running?: string, state?: string): Promise<any> => api.apiRequestGet(`timeclock/event-clockeds/${running ? running : 'all'}/${state ? state : 'all'}?fromDate=${(displayOnDates && displayOnDates.length > 1) ? displayOnDates[0]?.format(MOMENT_FORMAT_DATE_TO_NETWORK) : moment().subtract(1, 'days').format(MOMENT_FORMAT_DATE_TO_NETWORK)}&toDate=${(displayOnDates && displayOnDates.length === 2) ? displayOnDates[1]?.format(MOMENT_FORMAT_DATE_TO_NETWORK) : moment().format(MOMENT_FORMAT_DATE_TO_NETWORK)}`);
    static getTimeClockDevices = (type?: string, state?: string): Promise<any> => api.apiRequestGet(`timeclock/devices/${type ? type : 'all'}/${state ? state : 'all'}`);
    static getTimeClocktemplates = (templateId?: number): Promise<any> => api.apiRequestGet(`timeclock/templates/${templateId ? `?templateId=${templateId}` : ''}`);

    static initTimeClockDevices = (): Promise<any> => api.apiRequestPut(`timeclock/device/init`);
    static userPinTimeClock = (userId: number, force = false): Promise<any> => api.apiRequestGet(`user/current/timeclock/pin?userId=${userId}&force=${force}`);
    /**
     * Confirm event clocked
     * @param eventId Event ID
     * @param state Specified state (confirm | reject | set-admin | start | stop | pause) => 'confirm' by default
     * @returns a promise containing the response of the request
     */
    static confirmEventClocked = (eventId: number): Promise<any> => api.apiRequestPatch(`timeclock/event-clocked/${eventId}/confirm`);

    /**
     * Reject event clocked
     * @param eventId Event ID
     * @param state Specified state (confirm | reject | set-admin | start | stop | pause) => 'confirm' by default
     * @returns a promise containing the response of the request
     */
    static rejectEventClocked = (eventId: number): Promise<any> => api.apiRequestPatch(`timeclock/event-clocked/${eventId}/reject`);

    /**
     * Set admin time event clocked
     * @param eventId Event ID
     * @param parameters Request parameters of type TimeClockEventAdminBody
     * @returns a promise containing the response of the request
     */
    static setAdminEventClocked = (eventClockedId: number, parameters: EventClockedAdminBody): Promise<any> => api.apiRequestPatchWithBody(`timeclock/event-clocked/${eventClockedId}/set-admin`, parameters);

    static confirmOrRejectEventsWithClocked = (state: string, eventClockeds: NetworkEventClockedsConfirmRejectSetAdmin[]): Promise<any> => api.apiRequestPatchWithBody(`timeclock/event-clockeds/${state}/`, eventClockeds);

    static stopEventClocked = (eventClockedId: number): Promise<any> => api.apiRequestPatch(`timeclock/event-clocked/${eventClockedId}/stop`);

    static setManuallyEventClocked = (eventClockedId: number, parameters: NetworkEventClockedSetManually): Promise<any> => api.apiRequestPatchWithBody(`timeclock/event-clocked/${eventClockedId}/set-manually`, parameters);

    static getEventClockedsByDay = (selectedDays: Moment[], running?: string, state?: string): Promise<any> => api.apiRequestGet(`timeclock/event-clockeds/${running ? running : 'all'}/${state ? state : 'all'}?fromDate=${selectedDays[0].format(MOMENT_FORMAT_DATE_TO_NETWORK)}&toDate=${selectedDays[6].format(MOMENT_FORMAT_DATE_TO_NETWORK)}`);

    static getEventsWithEventClockedOnSelectedDays = (selectedDates: { startDate: Moment; endDate: Moment; }): Promise<any> => api.apiRequestGet(`timeclock/events?fromDate=${selectedDates.startDate.format(MOMENT_FORMAT_DATE_TO_NETWORK)}&toDate=${selectedDates.endDate.format(MOMENT_FORMAT_DATE_TO_NETWORK)}`);

    static setEventClockedData = (eventId: number, parameters: NetworkSetEventParameters): Promise<any> => api.apiRequestPatchWithBody(`timeclock/event/${eventId}/set-event`, parameters);
    static rejectAllBreakTimeClocked = (eventId: number): Promise<any> => api.apiRequestPatchWithBody(`timeclock/event/${eventId}/reject-all-breaks`);
    static getEventWithEventClocked = (eventId: number): Promise<any> => api.apiRequestGet(`timeclock/event/${eventId}/all`);



    static getMobileOverview = (): Promise<any> => api.apiRequestGet(`user/mobile-overview`);
    static updateMobileOverview = (data: { mobileAppSettings?: MobileAppSettings, theme?: MobileAppTheme; }): Promise<any> => {

        if (data.theme === undefined || (typeof data.theme.logo === 'string') || data.theme.logo === undefined) {
            return api.apiRequestPatchWithBody(`user/mobile-overview`, data);
        } else if (data.theme !== undefined) {
            //otherwise upload the image
            return new Promise((resolve, reject) => {
                //call to get image url
                return api.apiRequestGet('utils/signed-url').then(
                    response => {
                        const temp_url = response.sub_url;
                        const signed_url = response.sign_url;

                        const reader = new FileReader();

                        reader.onload = (e) => {
                            api.apiRequestFileUpload(signed_url, e.target?.result).then(
                                () => {
                                    if (data.theme) {
                                        data.theme.logo = GOOGLE_STORAGE_URL + temp_url;
                                    }
                                    resolve(api.apiRequestPatchWithBody(`user/mobile-overview`, data));
                                },
                                error => reject(error)
                            );
                        };

                        reader.readAsArrayBuffer(data.theme!.logo! as Blob);
                    },
                    error => reject(error)
                );
            });
        } else {
            return api.apiRequestPatchWithBody(`user/mobile-overview`, data);
        }


    };


    static getEffectiveHoursByUser = (startDate: Moment, endDate: Moment, userIds?: number[]): Promise<any> => api.apiRequestGet(`planning/user-hours?startDate=${startDate.format(MOMENT_FORMAT_DATE_TO_NETWORK)}&endDate=${endDate.format(MOMENT_FORMAT_DATE_TO_NETWORK)}${userIds ? `&userIds=${userIds.join("&userIds=")}` : ''}`);

    static saveBalanceYear = (data: BalanceYearRequestBody[], year: Moment): Promise<any> => api.apiRequest('planning/user-hours', { data: data, year: year.format("YYYY") });


    static updateEventReminder = (reminderId: number, eventId: number, minBeforeEventStart: number): Promise<any> => api.apiRequestPatchWithBody(`planning/event/reminders?reminderId=${reminderId}&eventId=${eventId}`, { minBeforeEventStart });
    static addEventReminder = (eventId: number, minBeforeEventStart: number): Promise<any> => api.apiRequestPutWithBody(`planning/event/reminders?eventId=${eventId}`, { minBeforeEventStart });
    static removeEventReminder = (reminderId: number, eventId: number): Promise<any> => api.apiRequestDelete(`planning/event/reminders?reminderId=${reminderId}&eventId=${eventId}`);

    static updateTemplateReminder = (reminderId: number, templateId: number, minBeforeEventStart: number): Promise<any> => api.apiRequestPatchWithBody(`planning/template/reminders?reminderId=${reminderId}&templateId=${templateId}`, { minBeforeEventStart });
    static addTemplateReminder = (templateId: number, minBeforeEventStart: number): Promise<any> => api.apiRequestPutWithBody(`planning/template/reminders?templateId=${templateId}`, { minBeforeEventStart });
    static removeTemplateReminder = (reminderId: number, templateId: number): Promise<any> => api.apiRequestDelete(`planning/template/reminders?reminderId=${reminderId}&templateId=${templateId}`);

    static importUsers = (users: NetworkUserImport[]): Promise<NetworkResponse<NetworkUserImportResponse[]>> => api.apiRequest(`user/import`, {users});
}