import { CloseCircleOutlined, DeleteOutlined, MinusCircleOutlined, MinusOutlined, PlusCircleOutlined, PlusOutlined, QuestionCircleOutlined } from '@ant-design/icons';
import { Badge, Calendar, Col, DatePicker, Divider, Empty, InputNumber, Radio, Row, Segmented, Select, Space } from 'antd';
import cloneDeep from 'lodash/cloneDeep';
import isArray from 'lodash/isArray';
import isEmpty from 'lodash/isEmpty';
import toFinite from 'lodash/toFinite';
import moment, { Moment } from 'moment';
import { Component, ReactNode } from 'react';
import isEqual from 'react-fast-compare';
import { BiChevronDown, BiChevronUp } from 'react-icons/bi';
import { BsStopwatch } from 'react-icons/bs';
import { GoPencil } from "react-icons/go";
import { TbUsers } from 'react-icons/tb';
import { defineMessages, FormattedMessage, injectIntl, IntlShape, MessageDescriptor } from 'react-intl';
import { connect, ConnectedProps } from 'react-redux';
import { Frequency, RRule, rrulestr } from 'rrule';
import { Rules } from '../../../../rbacRules';
import { BreaktimeCalculatedTypes, GREEN_COLOR, MOMENT_FORMAT_DISPLAY_TIME, MOMENT_FORMAT_TIME_MIN_GMT, WeekDays, WeekDaysMessages } from '../../../../utils/constants';
import getFormat from '../../../../utils/Lang';
import Network from '../../../../utils/network';
import { UserEventsData } from '../../../../utils/objects/cct/userEventsData';
import { IMissionEdit, IMissionRule, IMissionRuleType } from '../../../../utils/types/customerTypes';
import { PlanningTemplate } from '../../../../utils/types/planningTypes';
import { ApplicationState } from '../../../../utils/types/storeTypes';
import { colorIsBright, convertNetworkTemplatesToPlanningTemplates, convertPlanningEventToSimpleEvent, generateNegativeUniqueId, showNotification } from '../../../../utils/utils';
import { IntlProps } from '../../../app/LanguageProvider';
import CircleButton from '../../../common/fields/circleButton';
import Input from '../../../common/fields/input';
import Anticon from '../../../common/general/anticon';
import Can from '../../../common/general/can';
import Event, { EventSource } from '../../../common/general/event';
import Fieldset from '../../../common/general/fieldset';
import SpaceContent from '../../../common/general/spaceContent';
import TemplateModal from '../../../planning/templateModal';


// #region Constants
export const enum AvailabilityState {
    Open = 0,
    Close = 1
}

const enum ConstraintsFields {
    TitleMinLen = 4,
    TitleMaxLen = 255,
}

const enum DateOrTime {
    StartDate = "startdaterule",
    EndDate = "enddaterule",
    StartTime = "starttime",
    EndTime = "endtime"
}

export const enum AllDayTimes {
    start = "00:00",
    end = "23:59"
}

enum ProposalsValues {
    EachWeek = "eachweek",
    EachMonthDay = "eachmonthday",
    EachMontFirstX = "eachmontfirstx",
    EachMontLastX = "eachmontlastx",
}

// enum MonthlySetPosNames {
//     First = "Premier",
//     Second = "Deuxième",
//     Third = "Troisième",
//     Fourth = "Quatrième",
//     Fifth = "Cinquième",
//     Last = "Dernier",
// }

const enum MonthlySetPosNames {
    First = "first",
    Second = "second",
    Third = "third",
    Fourth = "fourth",
    Fifth = "fifth",
    Last = "last",
}

const MonthlySetPosNamesText = defineMessages<MonthlySetPosNames>({
    [MonthlySetPosNames.First]: { defaultMessage: 'First' },
    [MonthlySetPosNames.Second]: { defaultMessage: 'Second' },
    [MonthlySetPosNames.Third]: { defaultMessage: 'Third' },
    [MonthlySetPosNames.Fourth]: { defaultMessage: 'Fourth' },
    [MonthlySetPosNames.Fifth]: { defaultMessage: 'Fifth' },
    [MonthlySetPosNames.Last]: { defaultMessage: 'Last' },
});

const proposalsRules: {
    id: ProposalsValues;
    title: (itnl: IntlShape, weekday?: MessageDescriptor, date?: string) => ReactNode;
    rrule: (rrule: RRule, startDateRule: Moment | undefined) => RRule;
}[] =
    [
        {
            id: ProposalsValues.EachWeek,
            title: (intl, weekday) => <FormattedMessage defaultMessage={'Each week, on {weekday}'} values={{ weekday: weekday ? intl.formatMessage(weekday) : '' }} />,
            rrule: (rrule: RRule, startDateRule: Moment | undefined) => new RRule({
                ...rrule.origOptions,
                freq: RRule.WEEKLY,
                interval: 1,
                byweekday: startDateRule ? (startDateRule.isoWeekday() - 1) : undefined
            }),
        },
        {
            id: ProposalsValues.EachMonthDay,
            // eslint-disable-next-line @typescript-eslint/no-unused-vars
            title: (intl, weekday, date = "") => <FormattedMessage defaultMessage={'Each month, on {date}'} values={{ date }} />,
            rrule: (rrule: RRule, startDateRule: Moment | undefined) => new RRule({
                ...rrule.origOptions,
                freq: RRule.MONTHLY,
                interval: 1,
                bymonthday: startDateRule?.date()

            }),
        },
        {
            id: ProposalsValues.EachMontFirstX,
            title: (intl, weekday) => <FormattedMessage defaultMessage={'Each week, on first {weekday}'} values={{ weekday: weekday ? intl.formatMessage(weekday) : '' }} />,
            rrule: (rrule: RRule, startDateRule: Moment | undefined) => new RRule({
                ...rrule.origOptions,
                freq: RRule.MONTHLY,
                interval: 1,
                bysetpos: 1,
                byweekday: startDateRule ? (startDateRule.isoWeekday() - 1) : undefined

            }),
        },
        {
            id: ProposalsValues.EachMontLastX,
            title: (intl, weekday) => <FormattedMessage defaultMessage={'Each month, on last {weekday}'} values={{ weekday: weekday ? intl.formatMessage(weekday) : '' }} />,
            rrule: (rrule: RRule, startDateRule: Moment | undefined) => new RRule({
                ...rrule.origOptions,
                freq: RRule.MONTHLY,
                interval: 1,
                bysetpos: -1,
                byweekday: startDateRule ? (startDateRule.isoWeekday() - 1) : undefined

            }),
        },

    ];

export const monthlySetPosOptions = [
    {
        name: MonthlySetPosNamesText[MonthlySetPosNames.First],
        bysetpos: 1
    },
    {
        name: MonthlySetPosNamesText[MonthlySetPosNames.Second],
        bysetpos: 2
    },
    {
        name: MonthlySetPosNamesText[MonthlySetPosNames.Third],
        bysetpos: 3
    },
    {
        name: MonthlySetPosNamesText[MonthlySetPosNames.Fourth],
        bysetpos: 4
    },
    {
        name: MonthlySetPosNamesText[MonthlySetPosNames.Fifth],
        bysetpos: 5
    },
    {
        name: MonthlySetPosNamesText[MonthlySetPosNames.Last],
        bysetpos: -1
    },
];

export const weekDaysRRule = [
    {
        weekday: RRule.MO.weekday,
        name: WeekDaysMessages[WeekDays.MONDAY],
        value: RRule.MO.toString()
    },
    {
        weekday: RRule.TU.weekday,
        name: WeekDaysMessages[WeekDays.TUESDAY],
        value: RRule.TU.toString()
    },
    {
        weekday: RRule.WE.weekday,
        name: WeekDaysMessages[WeekDays.WEDNESDAY],
        value: RRule.WE.toString()
    },
    {
        weekday: RRule.TH.weekday,
        name: WeekDaysMessages[WeekDays.THURSDAY],
        value: RRule.TH.toString()
    },
    {
        weekday: RRule.FR.weekday,
        name: WeekDaysMessages[WeekDays.FRIDAY],
        value: RRule.FR.toString()
    },
    {
        weekday: RRule.SA.weekday,
        name: WeekDaysMessages[WeekDays.SATURDAY],
        value: RRule.SA.toString()
    },
    {
        weekday: RRule.SU.weekday,
        name: WeekDaysMessages[WeekDays.SUNDAY],
        value: RRule.SU.toString()
    },
];
// #endregion

type ReduxProps = ConnectedProps<typeof connector>;
interface Props extends ReduxProps, IntlProps {
    mission?: IMissionEdit;
    missionReadyToSave: (mission?: IMissionEdit) => void;
}
interface State {
    mission: IMissionEdit;
    proposal?: ProposalsValues;
    evenOdd: boolean;
    onceUnkown: boolean;
    templates?: PlanningTemplate[];
    newTemplate?: PlanningTemplate;
    temporaryTemplates?: PlanningTemplate[];
}

export class EditMission extends Component<Props, State> {
    constructor(props: Props) {
        super(props);

        this.state = this.getInitialState();
    }

    componentDidMount() {
        Network.getCRMTemplates().then(
            response => {
                this.setState({ templates: convertNetworkTemplatesToPlanningTemplates(response) });
            },
            () => {
                showNotification(this.props.intl.formatMessage({ defaultMessage: 'An error occurred while loading the models' }), "error");
            }
        );
    }

    componentDidUpdate(prevProps: Props, prevState: Readonly<State>) {
        if (!isEqual(this.state.mission, prevState.mission)) {
            this.checkMissionReadyToSave();
        }

        if (!isEqual(this.props.mission, prevProps.mission)) {
            this.setState({ ...this.getInitialState() });
        }
    }

    getInitialState = () => {
        const state: State = {
            mission: this.props.mission ? cloneDeep(this.props.mission) : { rules: [{ id: generateNegativeUniqueId() }] },
            evenOdd: false,
            onceUnkown: false,
        };

        state.mission.rules?.forEach(rule => {
            if (rule.main && rule.pairId) {
                state.evenOdd = true;
            }

            if (rule.type === IMissionRuleType.ONCE && state.mission.startDate && state.mission.endDate) {
                state.onceUnkown = true;
            }
        });
        return state;
    };

    copyRRuleOnlyLimits = (rrule: RRule | undefined) => {
        return new RRule({
            dtstart: rrule?.origOptions.dtstart,
            until: rrule?.origOptions.until
        });
    };

    getProposalsText = (val: ProposalsValues, ruleId: number) => {
        const rules = cloneDeep(this.state.mission?.rules);
        const rule = rules?.find(r => r.id === ruleId);
        if (rule) {
            const proposalFound = proposalsRules.find(p => p.id === val);
            if (proposalFound) {
                const weekday = weekDaysRRule.find(d => rule?.startDate && ((d.weekday + 1) === moment(rule.startDate).isoWeekday()))?.name;
                const monthday = moment(rule.startDate).format("D");
                return proposalFound.title(this.props.intl, weekday, monthday);
            } else {
                return '';
            }
        }
    };

    checkMissionReadyToSave = () => {
        const { mission } = this.state;
        const notReady = () => this.props.missionReadyToSave(undefined);

        if (!mission) { notReady(); return; }
        if (!mission.title) { notReady(); return; }
        if (!mission.startDate) { notReady(); return; }
        if (!mission.endDate) { notReady(); return; }

        if (!mission.rules || mission.rules.length === 0) {
            notReady();
            return;
        } else {
            let error = false;
            mission.rules.forEach(rule => {
                if (!rule.startDate) { error = true; }
                if (!rule.endDate && (rule.type !== IMissionRuleType.ONCE)) { error = true; }
                if (!rule.hoursQuantity) { error = true; }
                if (!rule.type || (rule.type === IMissionRuleType.RECCURENT && (!rule.rrule || rule.rrule.length === 0))) { error = true; }
                if (!rule.humanQuantity && rule.type !== IMissionRuleType.QUOTA) { error = true; }
            });

            if (error) { notReady(); return; }
        }

        this.props.missionReadyToSave({ ...mission, id: mission.id && mission.id > 0 ? mission.id : undefined });
    };

    onChangeTitle = (title: string) => {
        const mission = cloneDeep(this.state.mission);
        mission.title = title;
        this.setState({ mission });
    };

    onChangeType = (type: IMissionRuleType, ruleId?: number) => {
        if (!ruleId) return;
        const mission = cloneDeep(this.state.mission);
        const rule = mission.rules?.find(r => r.id === ruleId);
        if (rule) {
            rule.type = type;
            this.setState({ mission });
        }
    };

    onChangeStartDate = (date: Moment | null) => {
        const mission = cloneDeep(this.state.mission);
        if (date) {
            mission.rules?.forEach(rule => {
                if (rule.id) this.onChangeDateOrTime(date, DateOrTime.StartDate, rule.id);
            });
            this.setState(prevState => ({ mission: { ...prevState.mission, startDate: date.toISOString() } }));
        }
    };

    onChangeEndDate = (date: Moment | null) => {
        const mission = cloneDeep(this.state.mission);
        if (date) {
            mission.rules?.forEach(rule => {
                if (rule.id) this.onChangeDateOrTime(date, DateOrTime.EndDate, rule.id);
            });
            this.setState(prevState => ({ mission: { ...prevState.mission, endDate: date.toISOString() } }));
        }
    };

    stringToRrule = (rrule: string | undefined) => {
        return rrule && rrule.length > 0 ? rrulestr(rrule) : undefined;
    };

    onChangeDateOrTime = (val: Moment | null, field: DateOrTime, ruleId: number) => {
        const mission = cloneDeep(this.state.mission);
        const rule = mission.rules?.find(r => r.id === ruleId);
        if (rule && val) {
            let rrule = this.stringToRrule(rule.rrule);
            if (!rrule) {
                rrule = new RRule({
                    freq: RRule.DAILY
                });
            }
            if (val === null) return;
            switch (field) {
                case DateOrTime.StartDate:
                    rule.startDate = val.toISOString();
                    if (val.year() > 1970 && rule.endDate) {
                        rrule = new RRule({
                            ...rrule?.origOptions,
                            dtstart: val ? new Date(val.format(MOMENT_FORMAT_TIME_MIN_GMT)) : undefined,
                            until: val ? new Date(moment(rule.endDate).format(MOMENT_FORMAT_TIME_MIN_GMT)) : undefined
                        });
                    }
                    break;
                case DateOrTime.EndDate:
                    rule.endDate = val.toISOString();
                    if (val.year() > 1970 && rule.startDate) {
                        rrule = new RRule({
                            ...rrule?.origOptions,
                            dtstart: val ? new Date(moment(rule.startDate).format(MOMENT_FORMAT_TIME_MIN_GMT)) : undefined,
                            until: val ? new Date(val.format(MOMENT_FORMAT_TIME_MIN_GMT)) : undefined
                        });
                    }

                    break;
                case DateOrTime.StartTime:
                    rule.startTime = val.toISOString();
                    break;
                case DateOrTime.EndTime:
                    rule.endTime = val.toISOString();
                    break;
                default:
                    return;
            }

            rule.rrule = rrule.toString();
            this.setState({ mission });
        }
    };

    onChangeProposal = (val: ProposalsValues, ruleId: number) => {
        const mission = cloneDeep(this.state.mission);
        const rule = mission.rules?.find(r => r.id === ruleId);
        if (rule && val) {
            let rrule = this.copyRRuleOnlyLimits(this.stringToRrule(rule.rrule));

            const proposalFound = proposalsRules.find(p => p.id === val);
            if (proposalFound) {
                rrule = new RRule({ ...proposalFound.rrule(rrule, moment(rule.startDate)).origOptions });
                rule.rrule = rrule.toString();
                this.setState({ proposal: val, mission });
            } else {
                showNotification(this.props.intl.formatMessage({ defaultMessage: 'An error occurred while loading the proposal data ' }), "error");
                this.setState({ proposal: undefined });
            }
        }
    };

    onChangeTemplate = (val: number, ruleId: number) => {
        const mission = cloneDeep(this.state.mission);
        const rule = mission.rules?.find(r => r.id === ruleId);
        if (rule && val) {
            rule.templateId = val;
            rule.template = undefined;
            this.setState({ mission });
        }
    };

    onChangeTemporaryTemplate = (template: PlanningTemplate | undefined, ruleId: number) => {
        const mission = cloneDeep(this.state.mission);
        const rule = mission.rules?.find(r => r.id === ruleId);
        if (rule && template) {
            rule.templateId = undefined;
            rule.template = template;
            this.setState({ mission });
        }
    };

    onChangeFrequency = (val: number | string, ruleId: number) => {
        const mission = cloneDeep(this.state.mission);
        const rule = mission.rules?.find(r => r.id === ruleId);
        if (rule && val) {
            let rrule = this.copyRRuleOnlyLimits(this.stringToRrule(rule.rrule));

            val = toFinite(val);
            switch (val) {
                case RRule.DAILY:
                    rrule = new RRule({
                        ...rrule.origOptions,
                        freq: toFinite(val),
                        interval: 1
                    });
                    break;
                case RRule.WEEKLY:
                    rrule = new RRule({
                        ...rrule.origOptions,
                        freq: toFinite(val),
                        byweekday: [RRule.MO],
                        interval: 1
                    });
                    break;
                case RRule.MONTHLY:
                default:
                    rrule = new RRule({
                        ...rrule.origOptions,
                        freq: toFinite(val),
                        bymonthday: 1,
                        interval: 1
                    });
                    break;
            }
            rule.rrule = rrule.toString();

            this.setState({ mission, proposal: undefined });
        }
    };

    onChangeWeekDay = (val: number | number[], ruleId: number) => {
        const mission = cloneDeep(this.state.mission);
        const rule = mission.rules?.find(r => r.id === ruleId);
        if (rule) {
            let rrule = this.stringToRrule(rule.rrule);
            let weekdays = rrule?.options.byweekday;

            if (isArray(val)) val = val[0];
            if (weekdays) {
                if (weekdays.includes(val)) {
                    weekdays = weekdays.filter(wd => wd !== val);
                } else {
                    weekdays = weekdays.concat(val);
                }
            } else {
                weekdays = [val];
            }

            if (!rrule) {
                rrule = new RRule({
                    freq: RRule.DAILY
                });
            }

            rrule = new RRule({
                ...rrule?.origOptions,
                byweekday: weekdays
            });
            rule.rrule = rrule.toString();

            this.setState({ mission, proposal: undefined });
        }
    };

    onChangeMonthType = (isDate: boolean, ruleId: number) => {
        const mission = cloneDeep(this.state.mission);
        const rule = mission.rules?.find(r => r.id === ruleId);
        if (rule) {
            let rrule = this.stringToRrule(rule.rrule);
            if (!rrule) {
                rrule = new RRule({
                    freq: RRule.DAILY
                });
            }

            if (isDate) {
                rrule = new RRule({
                    ...rrule?.origOptions,
                    bymonthday: 1,
                    byweekday: undefined,
                    bysetpos: undefined

                });
            } else {
                rrule = new RRule({
                    ...rrule?.origOptions,
                    bysetpos: 1,
                    bymonthday: undefined,
                    byweekday: [RRule.MO]
                });
            }

            rule.rrule = rrule.toString();
            this.setState({ mission });
        }
    };

    onChangeMonthDay = (monthDay: number | null, ruleId: number) => {
        const mission = cloneDeep(this.state.mission);
        const rule = mission.rules?.find(r => r.id === ruleId);
        if (rule) {
            let rrule = this.stringToRrule(rule.rrule);

            if (!rrule) {
                rrule = new RRule({
                    freq: RRule.DAILY
                });
            }

            rrule = new RRule({
                ...rrule?.origOptions,
                bymonthday: monthDay
            });

            rule.rrule = rrule.toString();
            this.setState({ mission });
        }

    };

    onChangeSetPos = (val: number | number[], ruleId: number) => {
        const mission = cloneDeep(this.state.mission);
        const rule = mission.rules?.find(r => r.id === ruleId);
        if (rule) {
            let rrule = this.stringToRrule(rule.rrule);

            if (!rrule) {
                rrule = new RRule({
                    freq: RRule.DAILY
                });
            }

            if (isArray(val)) val = val[0];

            const monthlyOptions = monthlySetPosOptions.find(m => m.bysetpos === val);
            if (monthlyOptions) {
                rrule = new RRule({
                    ...rrule?.origOptions,
                    bysetpos: monthlyOptions.bysetpos
                });

                rule.rrule = rrule.toString();
                this.setState({ mission });
            } else {
                showNotification(this.props.intl.formatMessage({ defaultMessage: 'Option invalid' }), "error");
            }
        }
    };

    onIncreaseInterval = (ruleId: number) => {
        const mission = cloneDeep(this.state.mission);
        const rule = mission.rules?.find(r => r.id === ruleId);
        if (rule) {
            let rrule = this.stringToRrule(rule.rrule);

            if (!rrule) {
                rrule = new RRule({
                    freq: RRule.DAILY
                });
            }

            rrule = new RRule({
                ...rrule?.origOptions,
                interval: rrule ? ++rrule.options.interval : 1
            });

            rule.rrule = rrule.toString();
            this.setState({ mission });
        }
    };

    onDecreaseInterval = (ruleId: number) => {
        const mission = cloneDeep(this.state.mission);
        const rule = mission.rules?.find(r => r.id === ruleId);
        if (rule) {
            let rrule = this.stringToRrule(rule.rrule);

            if (!rrule) {
                rrule = new RRule({
                    freq: RRule.DAILY
                });
            }

            rrule = new RRule({
                ...rrule?.origOptions,
                interval: rrule ? --rrule.options.interval : 1
            });

            rule.rrule = rrule.toString();
            this.setState({ mission });
        }
    };

    onChangeHoursQuantity = (quantity: number | null, ruleId: number) => {
        const mission = cloneDeep(this.state.mission);
        const rule = mission.rules?.find(r => r.id === ruleId);
        if (rule) {
            rule.hoursQuantity = quantity ? Math.round(quantity * 36000) / 36000 : 1;
            this.setState({ mission });
        }
    };


    onChangeHumanQuantity = (quantity: number | null, ruleId: number) => {
        const mission = cloneDeep(this.state.mission);
        const rule = mission.rules?.find(r => r.id === ruleId);
        if (rule) {
            rule.humanQuantity = quantity ?? 1;
            this.setState({ mission });
        }
    };

    onDecreaseHumanQuantity = (ruleId: number) => {
        const mission = cloneDeep(this.state.mission);
        const rule = mission.rules?.find(r => r.id === ruleId);
        if (rule) {
            this.setState(prevState => {
                const mission = cloneDeep(prevState.mission);
                const rule = mission.rules?.find(r => r.id === ruleId);
                if (rule) {
                    rule.humanQuantity = rule.humanQuantity && rule.humanQuantity > 1 ? rule.humanQuantity - 1 : 1;

                    return { mission: mission };
                } else {
                    return { ...prevState };
                }
            });
        }
    };

    onIncreaseHumanQuantity = (ruleId: number) => {
        const mission = cloneDeep(this.state.mission);
        const rule = mission.rules?.find(r => r.id === ruleId);
        if (rule) {
            this.setState(prevState => {
                const mission = cloneDeep(prevState.mission);
                const rule = mission.rules?.find(r => r.id === ruleId);
                if (rule) {
                    rule.humanQuantity = rule.humanQuantity ? rule.humanQuantity + 1 : 2;

                    return { mission: mission };
                } else {
                    return { ...prevState };
                }
            });
        }
    };

    addChildRule = (ruleId: number, freq: Frequency) => {
        const mission = cloneDeep(this.state.mission);
        const rule = mission.rules?.find(r => r.id === ruleId);
        if (rule) {
            let rrule = this.stringToRrule(rule.rrule);

            if (!rrule) {
                rrule = new RRule({
                    freq: RRule.WEEKLY
                });
            }

            rrule = new RRule({
                ...rrule?.origOptions,
                interval: 2
            });

            rule.rrule = rrule.toString();
            rule.main = true;
            rule.pairId = generateNegativeUniqueId();

            let newStartDate: Moment;
            if (freq === RRule.WEEKLY) {
                newStartDate = moment(rule.startDate).add(1, "week").startOf("isoWeek");
            } else {
                newStartDate = moment(rule.startDate).add(1, "month").startOf("month");
            }
            const newRule = cloneDeep(rule);
            newRule.id = generateNegativeUniqueId();
            newRule.main = false;
            newRule.pairId = rule.pairId;
            newRule.startDate = newStartDate.toISOString();
            rrule = new RRule({
                ...rrule?.origOptions,
                dtstart: new Date(newStartDate.format(MOMENT_FORMAT_TIME_MIN_GMT)),
            });
            newRule.rrule = rrule.toString();

            mission.rules?.push(newRule);
            this.setState({ mission, evenOdd: true });
        }
    };

    deleteRule = (ruleId: number, main = false, pairId?: number) => {
        const mission = cloneDeep(this.state.mission);
        const stopEvenOdd = Boolean(pairId && main === false);
        mission.rules = mission.rules?.filter(r => r.id !== ruleId);
        this.setState(prevState => ({ mission, evenOdd: stopEvenOdd ? false : prevState.evenOdd }));
    };

    dateFullCellRender = (value: Moment, data: { dates: Date[], color: string; }[]) => {
        let daySpan = <span>{value.format(getFormat('DAY_SHORT'))}</span>;
        data.forEach(d => {
            const dateFound = d.dates.find(i => moment(i).isSame(value, "date"));
            if (dateFound) {
                daySpan = <div style={{ backgroundColor: d.color, borderRadius: '100%', margin: 'auto', display: 'flex', justifyContent: 'center', alignItems: 'center', height: '28px', width: '28px', color: colorIsBright(d.color) ? 'white' : '#383e42' }}>{daySpan}</div>;
            }
        });
        return daySpan;
    };

    renderRecurringDaily = (ruleId: number) => {
        const mission = cloneDeep(this.state.mission);
        const rule = mission.rules?.find(r => r.id === ruleId);
        if (rule) {
            const rrule = this.stringToRrule(rule.rrule);

            return (
                <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', width: '100%' }}>
                    <CircleButton
                        small
                        disabled={rrule && rrule.options.interval <= 1}
                        icon={<MinusOutlined />}
                        title={this.props.intl.formatMessage({ defaultMessage: 'Reduce the number of days' })}
                        placement="top"
                        onClick={() => this.onDecreaseInterval(ruleId)}
                    />
                    <p style={{ flex: '1', textAlign: 'center' }}>
                        {
                            !rrule || rrule.options.interval === 1 ?
                                <span><FormattedMessage defaultMessage={'Repeats every day'} /></span>
                                :
                                <span><FormattedMessage defaultMessage={'Repeats every {days} days'} values={{ days: rrule.options.interval }} /></span>
                        }
                    </p>
                    <CircleButton
                        small
                        icon={<PlusOutlined />}
                        title={this.props.intl.formatMessage({ defaultMessage: 'Increase the number of days' })}
                        placement="top"
                        onClick={() => this.onIncreaseInterval(ruleId)}
                    />
                </div>
            );
        }
        return <></>;
    };

    renderWeeklyRepeat = (rrule: RRule | undefined, ruleId: number) => {
        const { evenOdd } = this.state;

        return (
            <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', width: '100%' }}>
                <CircleButton
                    small
                    disabled={rrule && rrule.options.interval <= 1 || evenOdd}
                    icon={<MinusOutlined />}
                    title={this.props.intl.formatMessage({ defaultMessage: 'Reduce the number of weeks' })}
                    placement="top"
                    onClick={() => this.onDecreaseInterval(ruleId)}
                />
                <p style={{ flex: '1', textAlign: 'center' }}>
                    {
                        !rrule || rrule.options.interval === 1 ?
                            <span><FormattedMessage defaultMessage={'Repeat every week'} /></span>
                            :
                            <span><FormattedMessage defaultMessage={'Repeat every {weeks} weeks'} values={{ weeks: rrule.options.interval }} /></span>
                    }
                </p>
                <CircleButton
                    small
                    disabled={evenOdd}
                    icon={<PlusOutlined />}
                    title={this.props.intl.formatMessage({ defaultMessage: 'Increase the number of weeks' })}
                    placement="top"
                    onClick={() => this.onIncreaseInterval(ruleId)}
                />
            </div>
        );
    };

    renderWeeklyDays = (rrule: RRule | undefined, ruleId: number) => {
        const { isSmartphone, intl } = this.props;
        return (
            <div style={{ display: 'flex', justifyContent: 'space-between', width: '100%' }}>
                {
                    weekDaysRRule.map((day) => {
                        return (
                            <CircleButton
                                className="__weekdays-buttons"
                                key={`weekdays-${day.value}`}
                                value={day.value}
                                type={rrule?.options.byweekday?.includes(day.weekday) ? 'primary' : 'default'}
                                onClick={() => this.onChangeWeekDay(day.weekday, ruleId)}
                                icon={<div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', height: '100%' }}>{intl.formatMessage(day.name).substring(0, isSmartphone ? 1 : 2)}</div>}
                                title={intl.formatMessage(day.name)}
                            />
                        );
                    })
                }
            </div>
        );
    };

    renderMonthlyRepeat = (rrule: RRule | undefined, ruleId: number) => {
        const { evenOdd } = this.state;
        return (
            <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', width: '100%' }}>
                <CircleButton
                    small
                    disabled={rrule && rrule.options.interval <= 1 || evenOdd}
                    icon={<MinusOutlined />}
                    title={this.props.intl.formatMessage({ defaultMessage: 'Reduce the number of months' })}
                    placement="top"
                    onClick={() => this.onDecreaseInterval(ruleId)}
                />
                <p style={{ flex: '1', textAlign: 'center' }}>
                    {
                        !rrule || rrule.options.interval === 1 ?
                            <span><FormattedMessage defaultMessage={'Repeats every month'} /></span>
                            :
                            <span><FormattedMessage defaultMessage={'Repeats every {months} months'} values={{ months: rrule.options.interval }} /></span>
                    }
                </p>
                <CircleButton
                    small
                    disabled={evenOdd}
                    icon={<PlusOutlined />}
                    title={this.props.intl.formatMessage({ defaultMessage: 'Increase the number of months' })}
                    placement="top"
                    onClick={() => this.onIncreaseInterval(ruleId)}
                />
            </div>
        );
    };

    renderMonthlyDays = (rrule: RRule | undefined, ruleId: number) => {
        return (
            <div style={{ backgroundColor: 'rgba(0, 0, 0, 0.04)', padding: '10px', borderRadius: 'var(--border-radius)', width: '100%' }}>
                <Radio.Group
                    value={rrule && rrule.options.bymonthday.length > 0}
                    onChange={(val) => this.onChangeMonthType(val.target.value, ruleId)}>
                    <Space direction="vertical">
                        <Space>
                            <div>
                                <Radio value={true}><FormattedMessage defaultMessage={'Day n°'} /></Radio>
                            </div>
                            <div>
                                <InputNumber
                                    disabled={rrule && rrule.options.bymonthday.length === 0}
                                    value={rrule && rrule.options.bymonthday[0]}
                                    onChange={(val) => this.onChangeMonthDay(val, ruleId)}
                                    min={1}
                                    max={31}
                                    style={{ width: '60px', minWidth: '60px' }}
                                />
                            </div>
                        </Space>
                        <Space>
                            <Radio value={false}><FormattedMessage defaultMessage={'the'} /></Radio>
                            <Select
                                disabled={rrule && rrule.options.bymonthday.length > 0}
                                value={rrule?.options.bysetpos}
                                onChange={(val) => this.onChangeSetPos(val, ruleId)}
                                style={{ width: '110px' }}
                            >
                                {monthlySetPosOptions.map(o => <Select.Option key={`monthly-set-pos-${o.name}`} value={o.bysetpos}>{o.name.defaultMessage?.toString().toLocaleLowerCase()}</Select.Option>)}
                            </Select>
                            <Select
                                disabled={rrule && rrule.options.bymonthday.length > 0}
                                value={rrule?.options.byweekday}
                                onChange={(val) => this.onChangeWeekDay(val, ruleId)}
                                style={{ width: '100px' }}
                            >
                                {weekDaysRRule.map(p => <Select.Option key={`weekday-${p.value}`} value={p.weekday}>{this.props.intl.formatMessage(p.name).toLocaleLowerCase()}</Select.Option>)}
                            </Select>
                        </Space>
                    </Space>
                </Radio.Group>
            </div>
        );
    };

    renderRecurringYearly = () => {
        return <p></p>; //TODO Yearly
    };

    renderEvenOddTitle = (rule: Partial<IMissionRule>, weekly: boolean, color: string) => {
        const { intl } = this.props;
        const ruleId = rule.id;
        if (!ruleId) return;
        return (
            <SpaceContent style={{ justifyContent: 'space-between' }}>
                <Space>
                    <div title={intl.formatMessage({ defaultMessage: 'Badge color in calendar' })} style={{ width: '15px', height: '15px', borderRadius: '100%', backgroundColor: color }} />
                    <b>
                        <FormattedMessage
                            defaultMessage={'{count, plural, =0 {Even {type} from {start} to {end}} other {Odd {type} from {start} to {end}}}'}
                            values={{
                                count: moment(rule.startDate).week() % 2,
                                type: weekly ? intl.formatMessage({ defaultMessage: 'Week' }) : intl.formatMessage({ defaultMessage: 'Month' }),
                                start: moment(rule.startDate).format(getFormat('DATE')),
                                end: moment(rule.endDate).format(getFormat('DATE'))
                            }} />
                    </b>
                </Space>
                {
                    rule.pairId && rule.main === false ?
                        <DeleteOutlined
                            size={10}
                            className='__hover-zoom'
                            onClick={() => this.deleteRule(ruleId, rule.main, rule.pairId)}
                            title={intl.formatMessage({ defaultMessage: 'Delete odd/even configuration' })} />
                        : null
                }
            </SpaceContent>
        );
    };

    createTemplate = (duration: number | undefined, template?: PlanningTemplate) => {
        this.setState({
            newTemplate: isEmpty(template) ?
                {
                    id: generateNegativeUniqueId(),
                    startDate: moment(),
                    endDate: moment(),
                    title: "",
                    color: undefined,
                    shouldLast: duration
                }
                : cloneDeep({
                    ...template,
                    shouldLast: duration
                })
        });
    };

    renderRuleRequirements = (rule: Partial<IMissionRule>, ruleId: number) => {
        const { newTemplate, templates } = this.state;
        const { intl } = this.props;
        let template = rule.template;
        if (!template && rule.templateId) {
            template = templates?.find(t => t.id === rule.templateId);
        }

        console.log("YEISH is empty", template, isEmpty(template));

        console.log("YEISH render should last parent", rule.hoursQuantity);

        return (
            <Fieldset legendStyle={{ fontSize: '16px', marginLeft: '10px' }} legend={<FormattedMessage defaultMessage={'Requirements configuration'} />} >
                <div style={{ display: 'grid', gridTemplateColumns: '1fr 90px 90px', gridTemplateRows: 'repeat(3, 1fr)', columnGap: '5px', rowGap: '15px', justifyContent: 'center', placeItems: 'center' }}>
                    <div style={{ gridColumnStart: '2' }}><Anticon style={{ width: '30px' }}><TbUsers title={intl.formatMessage({ defaultMessage: 'Number of people' })} size={20} /></Anticon></div>
                    <div><Anticon style={{ width: '30px' }}><BsStopwatch title="Nombre d'heures" size={22} /></Anticon></div>
                    <p style={{ justifySelf: 'flex-start' }}><FormattedMessage defaultMessage={'Daily requirements :'} /> </p>
                    <SpaceContent align='center'><MinusCircleOutlined onClick={() => this.onDecreaseHumanQuantity(ruleId)} /><p>{rule.humanQuantity ?? 1}</p><PlusCircleOutlined onClick={() => this.onIncreaseHumanQuantity(ruleId)} /></SpaceContent>
                    <SpaceContent align='center'><DatePicker.TimePicker onChange={(val) => val && this.onChangeHoursQuantity(val.hours() + (val.minutes() / 60), ruleId)} style={{ width: '57px' }} size='small' suffixIcon={null} allowClear={false} format={getFormat('TIME_SHORT')} value={moment("00:00", MOMENT_FORMAT_DISPLAY_TIME).add(rule.hoursQuantity ?? 1, "hours")} /></SpaceContent>

                </div>
                <div style={{ position: 'relative' }}>
                    <SpaceContent>
                        <p style={{ justifySelf: 'flex-start', minWidth: '160px' }}>Modèle d'événement: </p>
                        {
                            isEmpty(template) ?
                                <Event
                                    event={{
                                        title: intl.formatMessage({ defaultMessage: 'No template' }),
                                        startDate: moment().hour(0).minute(0).second(0),
                                        endDate: moment().hour(0).minute(0).second(0),
                                        color: { title: '', color: '#ccc' },
                                    } as PlanningTemplate}
                                    source={EventSource.TEMPLATE}
                                    displayIcons
                                    displayTimes
                                />
                                :
                                <Event
                                    event={{
                                        ...template,
                                        startDate: template.startDate,
                                        endDate: template.endDate,
                                        color: { title: '', color: template.color?.color ?? 'var(--primary-color)' },
                                        totalHoursBreakTime: template ? UserEventsData.breaktimesDuration([convertPlanningEventToSimpleEvent(template)], BreaktimeCalculatedTypes.NOTPAID) : 0,
                                        breakTimes: template.breakTimes,
                                    } as PlanningTemplate}
                                    source={EventSource.TEMPLATE}
                                    displayIcons
                                    displayTimes
                                />
                        }
                    </SpaceContent>
                    <CircleButton
                        small
                        style={{ position: 'absolute', top: '-10px', right: '-10px' }}
                        icon={isEmpty(template) ? <PlusOutlined /> : <Anticon><GoPencil /></Anticon>}
                        title={isEmpty(template) ? intl.formatMessage({ defaultMessage: 'Assign a template' }) : intl.formatMessage({ defaultMessage: 'Replace a model' })}
                        placement="top"
                        onClick={() => this.createTemplate(rule.hoursQuantity, template)}
                    />

                    {/* <Select
                        placeholder="Choisissez un modèle d'événement"
                        style={{ width: '100%', overflow: 'hidden', gridColumnStart: 2, gridColumnEnd: 4 }}
                        value={rule.templateId}
                        onChange={(val) => this.onChangeTemplate(val, ruleId)}
                    >
                        {templates?.map(t => <Select.Option key={`template-${t.id}`} value={t.id}>{t.title}</Select.Option>)}
                    </Select> */}
                </div>
                {
                    isEmpty(newTemplate) ?
                        null
                        :
                        <TemplateModal
                            notToSave={true}
                            visible={!isEmpty(newTemplate)}
                            template={newTemplate}
                            isEdit={false}
                            onClose={() => this.setState({ newTemplate: undefined })}
                            onSave={(template?: PlanningTemplate) => {
                                this.onChangeTemporaryTemplate(template, ruleId);
                                this.setState(prevState => ({
                                    temporaryTemplates: (prevState.temporaryTemplates ?? []).concat(template ? [template] : []),
                                    newTemplate: undefined,
                                }));
                            }}
                        />
                }
            </Fieldset>
        );
    };

    renderRecurring = () => {
        const { mission, evenOdd } = this.state;
        const { intl } = this.props;

        const onyParentRules = mission.startDate && mission.endDate ? mission.rules?.filter(r => r.main !== false) : undefined;

        return (
            <SpaceContent direction='vertical' space={20} align='center'>
                <Space>
                    <span>Active du</span>
                    <DatePicker
                        placeholder={intl.formatMessage({ defaultMessage: 'Start date' })}
                        allowClear={false}
                        value={mission.startDate ? moment(mission.startDate) : undefined}
                        onChange={this.onChangeStartDate}
                        format={getFormat('DATE')}
                    />
                    <span>au</span>
                    <DatePicker
                        placeholder={intl.formatMessage({ defaultMessage: 'End date' })}
                        allowClear={false}
                        value={mission.endDate ? moment(mission.endDate) : undefined}
                        onChange={this.onChangeEndDate}
                        format={getFormat('DATE')}
                    />
                </Space>
                {
                    onyParentRules?.map(rule => {
                        const ruleId = rule.id;
                        if (ruleId === undefined) return null;
                        // TODO: For the moment, we're only checking the first rule, as it's virtually impossible to have 2 rules of different types.
                        const childRule = mission.rules?.filter(r => r.pairId && r.pairId === rule.pairId && r.main === false).at(0);
                        const childRrule = this.stringToRrule(childRule?.rrule);
                        const childAllDates = childRule && childRule.startDate && childRule.endDate && childRrule ? childRrule.all() : [];
                        const canCalculateChildRrules = childAllDates.length > 0;
                        const childPlaceHolderWaitingTotal = canCalculateChildRrules && childRule && Boolean(childRule.humanQuantity) && Boolean(childRule.hoursQuantity) ? null : <span>...</span>;
                        const childColor = childRule && moment(childRule.startDate).week() % 2 == 0 ? GREEN_COLOR : 'var(--primary-color)';
                        const childTotal = (childRule?.humanQuantity ?? 0) * (childRule?.hoursQuantity ?? 0) * childAllDates.length;

                        const rrule = this.stringToRrule(rule.rrule);
                        const allDates = rule.startDate && rule.endDate && rrule ? rrule.all() : [];
                        const canCalculateRrules = allDates.length > 0;
                        const weekly = rrule?.options.freq === RRule.WEEKLY;
                        const placeHolderWaitingTotal = canCalculateRrules && Boolean(rule.humanQuantity) && Boolean(rule.hoursQuantity) ? null : <span>...</span>;
                        const parentColor = childRule && moment(rule.startDate).week() % 2 == 0 ? GREEN_COLOR : 'var(--primary-color)';
                        const parentTotal = (rule.humanQuantity ?? 0) * (rule.hoursQuantity ?? 0) * allDates.length;

                        const calendarDates = [{ dates: allDates, color: parentColor }];
                        if (childRule) {
                            calendarDates.push({ dates: childAllDates, color: childColor });
                        }

                        let hoursQuantityText = "";
                        if (rule.hoursQuantity) {
                            const hoursQuantityDuration = moment.duration(Math.round(rule.hoursQuantity * 3600), 'seconds');
                            hoursQuantityText = `${Math.floor(hoursQuantityDuration.asHours())}h${hoursQuantityDuration.minutes() > 0 ? hoursQuantityDuration.minutes() : ''}`;
                        }
                        let totalHoursText = "";
                        const totalHoursDuration = moment.duration(Math.round(parentTotal * 3600), 'seconds');
                        totalHoursText = `${Math.floor(totalHoursDuration.asHours())}h${totalHoursDuration.minutes() > 0 ? totalHoursDuration.minutes() : ''}`;

                        return (
                            <>
                                <Divider style={{ margin: '0px', width: '100%' }} />
                                <div style={{ display: 'grid', gridTemplateColumns: '1fr 350px', gap: '20px', width: '100%' }}>
                                    <SpaceContent space={20} direction='vertical' align='flex-start'>
                                        {
                                            evenOdd ?
                                                this.renderEvenOddTitle(rule, weekly, parentColor)
                                                :
                                                null
                                        }

                                        <div style={{ display: 'grid', gridTemplateColumns: '1fr 1fr', gap: '20px', width: '100%' }}>
                                            {this.renderRuleRequirements(rule, ruleId)}
                                            <Fieldset legendStyle={{ fontSize: '16px', marginLeft: '10px' }} legend={<FormattedMessage defaultMessage={'Recurrence configuration'} />} >
                                                <SpaceContent space={20} direction='vertical'>
                                                    {
                                                        evenOdd ?
                                                            null
                                                            :
                                                            <Select
                                                                placeholder={<FormattedMessage defaultMessage={'Select a recurrence proposition'} />}
                                                                style={{ width: '100%', overflow: 'hidden' }}
                                                                onChange={(val) => this.onChangeProposal(val, ruleId)}
                                                            >
                                                                {Object.values(ProposalsValues).map(p => <Select.Option key={`proposal-value-${p}`} value={p}>{this.getProposalsText(p, ruleId)}</Select.Option>)}
                                                            </Select>
                                                    }
                                                    {
                                                        evenOdd ?
                                                            null
                                                            :
                                                            <Segmented block
                                                                style={{ width: '100%' }}
                                                                disabled={evenOdd}
                                                                options={[
                                                                    {
                                                                        label: intl.formatMessage({ defaultMessage: 'Day' }),
                                                                        value: RRule.DAILY.toString(),
                                                                    },
                                                                    {
                                                                        label: intl.formatMessage({ defaultMessage: 'Week' }),
                                                                        value: RRule.WEEKLY.toString(),
                                                                    },
                                                                    {
                                                                        label: intl.formatMessage({ defaultMessage: 'Month' }),
                                                                        value: RRule.MONTHLY.toString(),

                                                                    }
                                                                ]}
                                                                value={rrule?.options.freq.toString()}
                                                                onChange={(val) => this.onChangeFrequency(val.valueOf(), ruleId)} />
                                                    }
                                                    {
                                                        rrule?.options.freq === RRule.DAILY ?
                                                            <>
                                                                {
                                                                    this.renderRecurringDaily(ruleId)
                                                                }
                                                            </>
                                                            : rrule?.options.freq === RRule.WEEKLY ?
                                                                <>
                                                                    {evenOdd ? null : this.renderWeeklyRepeat(rrule, ruleId)}
                                                                    {this.renderWeeklyDays(rrule, ruleId)}
                                                                    {
                                                                        childRule ?
                                                                            null
                                                                            :
                                                                            <Col span={24}>
                                                                                <SpaceContent align='center' style={{ cursor: 'pointer' }} onClick={() => this.addChildRule(ruleId, RRule.WEEKLY)}>
                                                                                    <PlusCircleOutlined /><span><FormattedMessage defaultMessage={'Odd/even week configuration'} /></span>
                                                                                </SpaceContent>
                                                                            </Col>
                                                                    }
                                                                </>
                                                                : rrule?.options.freq === RRule.MONTHLY ?
                                                                    <>
                                                                        {evenOdd ? null : this.renderMonthlyRepeat(rrule, ruleId)}
                                                                        {this.renderMonthlyDays(rrule, ruleId)}
                                                                        {
                                                                            childRule ?
                                                                                null
                                                                                :
                                                                                <Col span={24}>
                                                                                    <SpaceContent align='center' style={{ cursor: 'pointer' }} onClick={() => this.addChildRule(ruleId, RRule.MONTHLY)}>
                                                                                        <PlusCircleOutlined /><span><FormattedMessage defaultMessage={'Odd/even month configuration'} /></span>
                                                                                    </SpaceContent>
                                                                                </Col>
                                                                        }
                                                                    </>
                                                                    : rrule?.options.freq === RRule.YEARLY ?
                                                                        <>
                                                                            {this.renderRecurringYearly()}
                                                                        </>
                                                                        : null
                                                    }
                                                </SpaceContent>
                                            </Fieldset>
                                        </div>

                                        {
                                            evenOdd && childRule ?
                                                <>
                                                    {this.renderEvenOddTitle(childRule, weekly, childColor)}
                                                    <div style={{ display: 'grid', gridTemplateColumns: '1fr 1fr', gap: '20px', width: '100%' }}>
                                                        {
                                                            rrule?.options.freq === RRule.WEEKLY ?
                                                                <>
                                                                    {this.renderRuleRequirements(childRule, childRule.id!)}
                                                                    <Fieldset legendStyle={{ fontSize: '16px', marginLeft: '10px' }} legend={<FormattedMessage defaultMessage={'Recurrence configuration'} />}>
                                                                        {this.renderWeeklyDays(childRrule, childRule.id!)}
                                                                    </Fieldset>
                                                                </>
                                                                : rrule?.options.freq === RRule.MONTHLY ?
                                                                    <>
                                                                        {this.renderRuleRequirements(childRule, childRule.id!)}
                                                                        <Fieldset legendStyle={{ fontSize: '16px', marginLeft: '10px' }} legend={<FormattedMessage defaultMessage={'Recurrence configuration'} />}>
                                                                            {this.renderMonthlyDays(childRrule, childRule.id!)}
                                                                        </Fieldset>
                                                                    </>
                                                                    : null
                                                        }
                                                    </div>
                                                </>
                                                : null
                                        }
                                    </SpaceContent>
                                    <SpaceContent align='center'>
                                        <Fieldset legendStyle={{ fontSize: '16px', marginLeft: '20px' }} style={{ padding: 0, overflow: 'hidden' }} legend={<FormattedMessage defaultMessage={'Final check'} />} >
                                            <Calendar fullscreen={false} dateFullCellRender={(val) => this.dateFullCellRender(val, calendarDates)} />
                                            {
                                                placeHolderWaitingTotal ?
                                                    null
                                                    :
                                                    <>
                                                        <Divider style={{ margin: 0 }} />
                                                        <SpaceContent style={{ margin: '10px 15px' }}>
                                                            <div title={intl.formatMessage({ defaultMessage: 'Badge color in calendar' })} style={{ width: '15px', height: '15px', borderRadius: '100%', backgroundColor: parentColor }} />

                                                            <p style={{ justifySelf: 'flex-start' }}>
                                                                {
                                                                    childPlaceHolderWaitingTotal ?
                                                                        <FormattedMessage defaultMessage={'Total:'} />
                                                                        :
                                                                        <FormattedMessage defaultMessage={'Subtotal:'} />
                                                                }
                                                            </p>
                                                            <div>{rule.humanQuantity}p</div>
                                                            <div>&bull; {allDates.length}j</div>
                                                            <div>&bull; {hoursQuantityText}</div>
                                                            <div> = {`${totalHoursText}`}</div>
                                                        </SpaceContent>
                                                    </>
                                            }
                                            {
                                                childPlaceHolderWaitingTotal ?
                                                    null
                                                    :
                                                    <>
                                                        <SpaceContent style={{ margin: '10px 15px' }}>
                                                            <div title={intl.formatMessage({ defaultMessage: 'Badge color in calendar' })} style={{ width: '15px', height: '15px', borderRadius: '100%', backgroundColor: childColor }} />

                                                            <p style={{ justifySelf: 'flex-start' }}><FormattedMessage defaultMessage={'Subtotal:'} /> </p>
                                                            <div>{childRule?.humanQuantity}p</div>
                                                            <div>* {childAllDates.length}j</div>
                                                            <div>* {childRule?.hoursQuantity}h</div>
                                                            <div> = {`${childTotal}h`}</div>
                                                        </SpaceContent>
                                                        <SpaceContent style={{ margin: '10px 15px' }}>
                                                            <div title={intl.formatMessage({ defaultMessage: 'Badge color in calendar' })} style={{ width: '15px', height: '15px', borderRadius: '100%', backgroundColor: parentColor }} />
                                                            <span>+</span>
                                                            <div title={intl.formatMessage({ defaultMessage: 'Badge color in calendar' })} style={{ width: '15px', height: '15px', borderRadius: '100%', backgroundColor: childColor }} />
                                                            <p style={{ justifySelf: 'flex-start' }}> <FormattedMessage defaultMessage={'Total:'} /> </p>
                                                            <div>{`${parentTotal + childTotal}h`}</div>
                                                        </SpaceContent>
                                                    </>
                                            }
                                            <SpaceContent>
                                                <></>
                                            </SpaceContent>
                                        </Fieldset>
                                    </SpaceContent>
                                </div>
                            </>
                        );
                    })
                }
            </SpaceContent >
        );
    };

    renderQuota = () => {
        const { mission } = this.state;

        // TODO: For the moment, we're only checking the first rule, as it's virtually impossible to have 2 rules of different types.
        const missionRule = mission.rules?.at(0);
        const ruleId = missionRule?.id;

        if (!missionRule || !ruleId) return null;
        return (
            <SpaceContent style={{ flexWrap: 'wrap' }}>
                <span><FormattedMessage defaultMessage={'The mission has a quota of'} /></span>
                <InputNumber
                    value={missionRule.hoursQuantity}
                    onChange={(val) => this.onChangeHoursQuantity(val, ruleId)}
                    size='small'
                    min={0}
                    style={{ width: '80px', minWidth: '80px' }}
                />
                <span><FormattedMessage defaultMessage={'hours, valid from'} /></span>
                <DatePicker
                    value={mission.startDate ? moment(mission.startDate) : undefined}
                    onChange={this.onChangeStartDate}
                    allowClear={false}
                    style={{ width: '120px' }}
                    format={getFormat('DATE')}
                    placeholder=''
                    size='small' />
                <span><FormattedMessage defaultMessage={'to'} /></span>
                <DatePicker
                    value={mission.endDate ? moment(mission.endDate) : undefined}
                    onChange={this.onChangeEndDate}
                    allowClear={false}
                    style={{ width: '120px' }}
                    format={getFormat('DATE')}
                    placeholder=''
                    size='small' />
            </SpaceContent>
        );
    };

    renderOnce = () => {
        const { mission, onceUnkown } = this.state;
        const { intl } = this.props;

        // TODO: For the moment, we're only checking the first rule, as it's virtually impossible to have 2 rules of different types.
        const missionRule = mission.rules?.at(0);
        const ruleId = missionRule?.id;

        if (!missionRule || !ruleId) return null;
        return (
            <SpaceContent direction='vertical' align='flex-start'>
                <SpaceContent style={{ flexWrap: 'wrap' }}>
                    <span><FormattedMessage defaultMessage={'The mission will take place {between}'} values={{ between: onceUnkown ? 'between' : 'on' }} /></span>
                    <Badge count={onceUnkown ? 0 : <QuestionCircleOutlined title='Date fixe inconnue?' onClick={() => this.setState({ onceUnkown: true })} />}>
                        <DatePicker
                            value={mission.startDate ? moment(mission.startDate) : undefined}
                            onChange={this.onChangeStartDate}
                            allowClear={false}
                            style={{ width: '120px' }}
                            format={getFormat('DATE')}
                            placeholder=''
                            size='small' />
                    </Badge>
                    {
                        onceUnkown ?
                            <>
                                <span><FormattedMessage defaultMessage={'and'} /></span>
                                <Badge count={onceUnkown ? <CloseCircleOutlined title={intl.formatMessage({ defaultMessage: 'Fixed date known?' })} onClick={() => this.setState({ onceUnkown: false })} /> : 0}>
                                    <DatePicker
                                        value={mission.endDate ? moment(mission.endDate) : undefined}
                                        onChange={this.onChangeEndDate}
                                        allowClear={false}
                                        style={{ width: '120px' }}
                                        format={getFormat('DATE')}
                                        placeholder=''
                                        size='small' />
                                </Badge>
                            </>
                            : null
                    }
                    <span><FormattedMessage defaultMessage={'and will require'} /></span>
                    <SpaceContent space={-5} direction='vertical' align='center'><BiChevronUp className='__hover-zoom' onClick={() => this.onIncreaseHumanQuantity(ruleId)} /><p>{missionRule.humanQuantity ?? 1}</p><BiChevronDown className='__hover-zoom' onClick={() => this.onDecreaseHumanQuantity(ruleId)} /></SpaceContent>
                    <span>
                        {
                            !missionRule || !missionRule.humanQuantity || missionRule.humanQuantity === 1 ?
                                <FormattedMessage defaultMessage={'people'} />
                                :
                                <FormattedMessage defaultMessage={'peoples'} />
                        }
                        <FormattedMessage defaultMessage={'For a period of'} />
                    </span>
                    <SpaceContent align='center'><DatePicker.TimePicker onChange={(val) => val && this.onChangeHoursQuantity(val.hours() + (val.minutes() / 60), ruleId)} style={{ width: '57px' }} size='small' suffixIcon={null} allowClear={false} format={getFormat('TIME_SHORT')} value={moment("00:00", MOMENT_FORMAT_DISPLAY_TIME).add(missionRule.hoursQuantity ?? 1, "hours")} /></SpaceContent>
                    <span>
                        {
                            !missionRule || !missionRule.hoursQuantity || missionRule.hoursQuantity === 1 ?
                                <FormattedMessage defaultMessage={'hour'} />
                                :
                                <FormattedMessage defaultMessage={'hours'} />
                        }
                    </span>
                </SpaceContent>
            </SpaceContent>
        );
    };

    render() {
        const { mission } = this.state;
        const { intl } = this.props;
        // TODO: For the moment, we're only checking the first rule, as it's virtually impossible to have 2 rules of different types.
        const missionRule = mission.rules?.at(0);
        return (
            <Can rule={Rules.CustomerManagement.Visit} redirect="/dashboard">
                <Row gutter={[20, 20]}>
                    <Col xs={{ span: 24 }} >
                        <Input
                            placeholder={intl.formatMessage({ defaultMessage: 'Title' })}
                            showCount
                            value={mission.title}
                            onChange={e => this.onChangeTitle(e.target.value)}
                            maxLength={ConstraintsFields.TitleMaxLen} />
                    </Col>
                    <Col xs={{ span: 24 }} md={{ span: 24 }}>
                        <Fieldset
                            legendStyle={{ width: '500px' }}
                            legend={
                                <Radio.Group
                                    buttonStyle="solid"
                                    style={{ width: '100%', display: 'flex', justifyContent: 'center' }}
                                    value={missionRule?.type}
                                    onChange={(val) => this.onChangeType(val.target.value, missionRule?.id)}>
                                    <Radio.Button style={{ borderRadius: "18px 0px 0px 18px", overflow: 'hidden', width: "calc(100% / 3)", textAlign: "center" }} value={IMissionRuleType.RECCURENT}><FormattedMessage defaultMessage={'Recurring'} /></Radio.Button>
                                    <Radio.Button style={{ width: "calc(100% / 3)", textAlign: "center", overflow: 'hidden' }} value={IMissionRuleType.ONCE}><FormattedMessage defaultMessage={'Unique'} /></Radio.Button>
                                    <Radio.Button style={{ borderRadius: "0px 18px 18px 0px", overflow: 'hidden', width: "calc(100% / 3)", textAlign: 'center' }} value={IMissionRuleType.QUOTA}><FormattedMessage defaultMessage={'Quota'} /></Radio.Button>
                                </Radio.Group>
                            }
                        >
                            {
                                missionRule?.type === IMissionRuleType.RECCURENT ?
                                    this.renderRecurring()
                                    : missionRule?.type === IMissionRuleType.QUOTA ?
                                        this.renderQuota()
                                        : missionRule?.type === IMissionRuleType.ONCE ?
                                            this.renderOnce()
                                            : <Empty description={<p><FormattedMessage defaultMessage={'Please select the type of mission'} /></p>} />
                            }
                        </Fieldset>
                    </Col >
                </Row >
            </Can>
        );
    }
}

const mapStateToProps = (state: ApplicationState) => ({
    isSmartphone: state.window.isSmartphone,
});

const connector = connect(mapStateToProps);

export default connector(injectIntl(EditMission));